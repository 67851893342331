import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

function GoogleAnalyticsTraker() {
    const location = useLocation();

    React.useEffect(() => {
        // Suivi d'une page vue pour GA4
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
  
    return null; 
}

export default GoogleAnalyticsTraker;