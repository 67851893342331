import React from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function Captcha({ onChange }) {
    const key=process.env.REACT_APP_SITEKEY_CAPTCHA;

    return (
        <HCaptcha
          sitekey={key}
          onVerify={onChange}
        />
  );
}

export default Captcha;