import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/reset/reset.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexte/Auth'; 
import ReactGA from 'react-ga4';

ReactGA.initialize('G-YZ8SCM5BLM');


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <AuthProvider>
        <div className='global-app'>
          <App />
        </div>
      </AuthProvider>

);

reportWebVitals();
