import React, { useState } from 'react'
import '../../styles/components/Buttons.css';
import UserRegister from '../formLoginContact/UserRegister';

function RegisterButton() {
    const [showRegister, setShowRegister] = useState(false);

    const handleClick = () => {
        setShowRegister(true);
    }
    const handleClose = () => {
        setShowRegister(false);
        
    }

  return (
    <div>
        <button className='buttons-login' onClick={handleClick}>S'enregistrer</button>
        {showRegister ?( <UserRegister onClose={handleClose} />):(<div></div>)}
    </div>

  )
}

export default RegisterButton