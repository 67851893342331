import React, { useState,useEffect } from 'react';
import { UserService } from '../_services/UserService';
import swal from 'sweetalert';
import '../../styles/components/History.css';


function History() {
  const [isHistoryTransactionPresent, setIsHistoryTransactionPresent]=useState(false);
  const [isHistoryCreditUsedPresent, setIsHistoryCreditUsedPresent]=useState(false);
  
  const [isHistoryTransactionData, setIsHistoryTransactionData]=useState(""); 
  const [isHistoryCreditUsedData, setIsHistoryCreditUsedData]=useState("");
  
  const [visibleDetails, setVisibleDetails] = useState({});

  useEffect(() => {
    UserService.getTransactionHistory()
    .then((response)=>{
    if(response.data.statusCode && response.data.statusCode===404){
      //Passage du state de presence de data a false
      setIsHistoryTransactionPresent(false);

    }else{
      //Passage du state de presence de data a true
      setIsHistoryTransactionPresent(true);
      
      const history = response.data.historyTransactionUser;
      //Enregistrement de la data dans le state historyData

      setIsHistoryTransactionData(history)
      }
    })
    .catch((error)=>{
      swal("Une erreur s'est produit, si le problème persiste, contacter l'administrateur", error);
    })
  }, [])
  
  useEffect(() => {
    UserService.getHistoryCreditUsed()
    .then((response)=>{
      if(response.data.statusCode && response.data.statusCode===404){
        //Passage du state de presence de data a false
        setIsHistoryCreditUsedPresent(false);
      }else{
        //Passage du state de presence de data a true
        setIsHistoryCreditUsedPresent(true);
        //Creation de la variable a injecter dans le state
        const history = response.data.historyCreditUsedUser;
        //Enregistrement de la data dans le state historyData
        setIsHistoryCreditUsedData(history)
      }
    })
    .catch((error)=>{
      swal("Une erreur s'est produit, si le problème persiste, contacter l'administrateur", error);
    })
  }, [])

  const toggleDetails = (id) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }
  
  return (
    <div className='component-history'>
      <div className='bloc-history-transaction'>
        <h2>Vos historiques</h2>
        <h3>Historique de vos achats de crédits :</h3>
          {isHistoryTransactionPresent ?
          (
            <div className='bloc-response-history'>
              {isHistoryTransactionData && isHistoryTransactionData.map((transaction)=>(
              <div key={transaction.id_transaction_client} className='bloc-by-transaction'>
                            <button className='button-open-detail' onClick={() => toggleDetails(transaction.id_transaction_client)}>
              {visibleDetails[transaction.id_transaction_client] ? "Masquer les détails" : "Voir les détails"}
            </button>
                <h5>En date du  </h5>
                  <p className='desciption-text-transaction'>{transaction.created_at.split("T")[0].split("-").reverse().join("/")}</p>
                  {visibleDetails[transaction.id_transaction_client]&&(
                    <>
                    <h5>Numéro de transaction </h5>
                      <p className='desciption-text-transaction'>{transaction.id_transaction_client}</p>
                    <h5>Solde de la transaction </h5>
                      <p className='desciption-text-transaction'>{transaction.amount/100}e</p>
                    <h5>Nombre de crédit reçu </h5>
                      <p className='desciption-text-transaction'>{transaction.number_of_credit}</p>
                    </>
                  )}
              </div>
                    ))}
            </div>
          ):(
            <div className='bloc-response-history'>
                <h4>Aucun achat de crédit n'a été éffectué à ce jour.</h4>
            </div>
          )}
      </div>
      <br/><br/> 
    <div className='bloc-history-transaction'>
      <h3>Historique de l'utilisation de vos crédits :</h3>

        {isHistoryCreditUsedPresent ?
        (
          <div className='bloc-response-history'>
          {isHistoryCreditUsedData && isHistoryCreditUsedData.map((credit)=>(
            <div key={credit.id_credit_used_client} className='bloc-by-transaction'>
                                          <button className='button-open-detail' onClick={() => toggleDetails(credit.id_credit_used_client)}>
              {visibleDetails[credit.id_credit_used_client] ? "Masquer les détails" : "Voir les détails"}
            </button>
              <h5>En date du  </h5>
              <p className='desciption-text-transaction'>{credit.created_at.split("T")[0].split("-").reverse().join("/")}</p>
              {visibleDetails[credit.id_credit_used_client]&&(
              <>
              <h5>Nom du service </h5>
                <p className='desciption-text-transaction'>{credit.product_name}</p>
              <h5>Nombre de crédit utilisé pour ce service</h5>
                <p className='desciption-text-transaction'>{credit.product_price_credit}</p>    
              </> )}           
            </div>
          ))}
        </div>
          ):(
          <div className='bloc-response-history'>
            <h4>Vous n'avez pas encore utilisé vos crédits.</h4>
        </div>
        )}
      </div>
      <br/><br/>
    </div>
  )
}

export default History