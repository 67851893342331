import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { AccountServicePublic } from '../_services/AccountService';
import '../../styles/components/CookieConsent.css';


function CookieConsentComp({ onConsentChange }) {

  function handleAccept() {
    onConsentChange(true)
    AccountServicePublic.cookiesConsentChoice(true)
  }
  
  function handleDecline() {
    onConsentChange(false)
    AccountServicePublic.cookiesConsentChoice(true)
  }
  return (
<CookieConsent
  onAccept={handleAccept}
  onDecline={handleDecline}
  location="bottom"  
  buttonText="J'accepte"
  declineButtonText="Je refuse"
  buttonWrapperClasses="cookie-button-wrapper"
  cookieName="user_consent"
  enableDeclineButton
  expires={150}
  containerClasses="cookie-consent-container" 
  buttonClasses="cookie-consent-button" 
  declineButtonClasses="cookie-consent-button" 
>
      <div className='bloc-cookies'>
        <div>
        <p>Cette application nécessite l'utilisation de cookies dont certains sont essentiels sur certaines pages pour fonctionner correctement.</p>
        <br/>
        <h3>En refusant, seulement les cookies indispensables seront utilisés pour :</h3>
        <br/>          
        <li>Assurer la sécurité de votre session </li>
        <li>Fournir des fonctionnalités essentielles de l'application</li>
        <li>Analyser et suivre le comportement des utilisateurs et l'audience concernant ce site, les données ne seront pas utilisées pour des fins commerciales.</li>
        <h3>En acceptant, tous les cookies seront autorisés dont :</h3>
        <li>les cookies liés aux opérations relatives à la publicité personnalisée </li>
        <li>les cookies des réseaux sociaux, notamment générés par leurs boutons de partage </li>
        <br/>        </div>
        <div>
          <img className='image-cookies' src='/images/Cookies.png' alt='Cookies'/>
        </div>
      </div>
        <nav>
        <a href="/PrivacyPolicies"className='link-policies'> Politique de confidentialité</a>, vous pouvez changer d'avis à tout moment en cliquant sur les cookies en bas de page.
        </nav>

    </CookieConsent>
  )
}

export default CookieConsentComp