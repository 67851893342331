import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { UserService } from "../_services/UserService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexte/Auth";
import Loading from '../icone/Loading';
import "../../styles/components/UserLogin.css";

function UserLogin(props) {
  const { login, isAuthenticated } = useAuth();
  const [isVerifcationCode, setIsVerifactionCode]=useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] =useState("");

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isTemporyPasswordSended, setIsTemporyPasswordSended] = useState(false);
  const [isButtonClicked, setIsButtonClicked]=useState(false);

  const [isLoading, setIsLoading]=useState(false);

  const forgetPassword=()=>{
    setIsForgotPassword(true);
  }
  //State visionner mot de passe par utilisateur
  const [showPassword, setShowPassword] = useState(false);

  //Fonction voir mot de passe
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userLogin = {
      email: email,
      password: password,
      code_activation:code,
    };
    UserService.postLoginUser(userLogin)
      .then((res) => {
        if(res.error){
          swal(res.error.data.message);
        }
          if (res.status === 201){
            setIsVerifactionCode(true);
          }
          if (res.status === 200) {
            login(res);
          }        
      })
      .catch((error) => {
        if (error.response.data.message) {
          swal(error.response.data.message);
        } else {
          swal(error);
        }
      });
  };
  const handleSubmitForRecoveryPass=(e)=>{
    e.preventDefault();
    setIsButtonClicked(true);
    setIsLoading(true);

    const data= {email:email};
    UserService.postRecoveryPassword(data)
      .then((res)=>{
        if(res.error){
          swal(`${res.error.data.message}`);
          setIsButtonClicked(false);
          setIsLoading(false);
        }
       setIsTemporyPasswordSended(true);
       setIsLoading(false);
      })
      .catch((error)=>{
        setIsButtonClicked(false);
        setIsLoading(false);
        swal("Problème lors de la récupération du mot de passe, veuillez me contacter si le problème persiste");
      });
  }
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleCloseClick = () => {
    if (props.onClose) {
      props.onClose();
  }   
  };
  return (
    <div className="modal">
      <button className="closeButton" onClick={handleCloseClick}>
        Fermer
      </button>
    {isVerifcationCode ?(
    <div className="login-container">
    <form className="form-modal" onSubmit={handleSubmit}>
        <h4>Veuillez saisir le code reçu par email à votre inscription :</h4>
      <div className="form-group">
        <label className="label">code de vérification :</label>
        <input
          className="input-modal"
          type="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
      </div>
      <button className="buttonFormulaire" type="submit">Se connecter</button>
    </form>
 </div>
    ):(
      <div className="login-container">
          {isForgotPassword ?(
          <div>
            {isTemporyPasswordSended ?(<div>
              <h3>Veuillez vérifier votre email pour pouvoir vous reconnecter.</h3>
            </div>
            ):(
              <div>
              {isLoading ?(
              <div>
                <Loading />
              </div>
                ):(
                <div>
                  <form className="form-modal" onSubmit={handleSubmitForRecoveryPass}>
                      <div className="form-group">
                        <label className="label">Email :</label>
                        <div className="sectionEmailLogin">
                          <input
                            className="input-modal"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                            <button className="buttonFormulaire" type="submit" disabled={isButtonClicked}>Envoyer</button>
                          </div>
                    </form>
                  </div>
                  )}
            </div>     
            )}
          </div>
          ):(
          <div>
            <form className="form-modal" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="label">Email :</label>
            <div className="sectionEmailLogin">
              <input
                className="input-modal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label">Mot de passe :</label>
            <div className="sectionPasswordLogin">
            <input
              className="input-modal"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          <button className="buttonPasswordCheckedLogin" type="button" onClick={handleTogglePassword}>
            {showPassword ? '*' : '*'} 
          </button>
          </div>
          <br/>
          <div>
            <span className="span-Password-Texte" onClick={forgetPassword}>Mots de passe oublié</span>
          </div>
          </div>
          <button className="buttonFormulaire" type="submit">Se connecter</button>
        </form>
          </div>)}
        
      </div>)}  
    </div>
  );
}

export default UserLogin;
