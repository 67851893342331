import React, { useEffect, useState } from "react";
import { useAuth } from '../../contexte/Auth';
import { UserService } from "../_services/UserService";
import useValidation from "../../hooks/useValidation";
import swal from "sweetalert";
import "../../styles/components/ProfilUser.css";
import UpdatePassword from "../checkoutForm/UpdatePassword";

function Profil() {
  //STATE
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isProfilDataAmanded, setIsProfilDataAmanded] = useState({
    name: "",
    first_name: "",
    country: "",
    town: "",
    date_of_birth: "",
    address: "",
    postal_code: "",
    telephone_number: "",
    email: "",
    newletter: false,
    credit: "",
    password:"",
  });
  const { logout } = useAuth();

  const currentDate = new Date().toISOString().split("T")[0];

  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const updatePassword =()=>{
    setIsUpdatePassword(true);
  };

  //Iniatilisé donnée du profil
  useEffect(() => {
    UserService.getProfilUser()
      .then(({ data, status, error }) => {      
        if(status===200){
          setIsProfilDataAmanded({
            name: data.name || "",
            first_name: data.first_name || "",
            country: data.country || "",
            town: data.town || "",
            date_of_birth: data.date_of_birth || "",
            address: data.address || "",
            postal_code:  data.postal_code ||"",
            telephone_number: data.telephone_number || "",
            email: data.email || "",
            newletter: data.newletter || false,
            credit: data.credit || 0,
            password: "", // keep password empty
          });
        }else{
          if (error.status === 500) {
            swal(error.data.message)
              .then(()=>{
                logout();
              })            
          }
        }
      })
      .catch((error) => {
        swal("Une erreur inattendue est survenue. Veuillez réessayer.");
      });
  }, [logout]);

  const [errors, validate] = useValidation();

  // Gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    //Faire disparaitre le button envoyé pour éviter double click
    setIsButtonClicked(true); 

      const isValid = await validate(isProfilDataAmanded);
      if (isValid) {
    // Convertir les données de profil, en changeant 'newletter' de booléen à chaîne
    const profilDataForSubmission = {
      ...isProfilDataAmanded,
      newletter: isProfilDataAmanded.newletter ? 'true' : 'false',
    };

    UserService.updateProfilUser(profilDataForSubmission)
      .then(({ data, status, error }) => { 
        if(status===200){
          swal("Votre profil a bien été modifié");
          // Réinitialiser le champ du mot de passe après une soumission réussie
          setIsProfilDataAmanded(prevState => ({
            ...prevState,
            password: '', // Réinitialiser le mot de passe
          }));
        }else{
          if(error.data.message.validatorDataErrors.length>0){
            let messageError = "";
            for (let index = 0; index < error.data.message.validatorDataErrors.length; index++) {
             const message = error.data.message.validatorDataErrors[index].message;
             
             messageError= messageError+""+ message;
            }
            if(error.data.message.validatorDataErrors.length>1){          
            messageError= messageError.replace('.',' et ');
            swal(messageError+"Veuillez vérifier votre saisie");
            }else{
              swal(messageError+"Veuillez vérifier votre saisie");
            }   
          }
        }
      })
       .catch((error) => {
        //réactiver le bouton Envoyer après la validation réussie
        setIsButtonClicked(false);
        swal("Une erreur inattendue est survenue. Veuillez réessayer.");
      });

      } else {
        setIsButtonClicked(false);
      }
      // réactiver le bouton Envoyer après la validation réussie
      setIsButtonClicked(false);

  };


  // Gérer les changements dans les champs de formulaire
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setIsProfilDataAmanded({
      ...isProfilDataAmanded,
      [name]: value,
    });
  };

  return (
    <div>
      {isUpdatePassword ?(
      <div>        
        <UpdatePassword />
      </div>
      ):(
        <div>
        <h2>Votre profil </h2>
         <form className="form-modal" onSubmit={handleSubmit}>
            <p id="text-up-password-link" onClick={updatePassword}>(Modifier le mot de passe)</p>
          <p>Credit(s) disponible(s) : {isProfilDataAmanded.credit}</p>
          <label className="label-modal">Nom :</label>
          <input
            className="input-modal"
            type="text"
            name="name"
            value={isProfilDataAmanded.name}
            onChange={handleChange}
            placeholder="Nom"
            required
            autocomplete="family-name"
          />
          {errors.name && <span style={{color: 'red'}}>{errors.name}</span>} 
          <label className="label-modal">Prénom :</label>
          <input
            className="input-modal"
            type="text"
            name="first_name"
            value={isProfilDataAmanded.first_name}
            onChange={handleChange}
            placeholder="Prénom"
            required
            autocomplete="given-name"
          />
          {errors.first_name && <span style={{color: 'red'}}>{errors.first_name}</span>} 
          <label className="label-modal">Ville :</label>
          <input
            className="input-modal"
            type="country"
            name="country"
            value={isProfilDataAmanded.country}
            onChange={handleChange}
            placeholder="Ville"
            autocomplete="address-level2"
          />
          {errors.country && <span style={{color: 'red'}}>{errors.country}</span>}           
          
          <label className="label-modal">Pays :</label>
          <input
            className="input-modal"
            type="town"
            name="town"
            value={isProfilDataAmanded.town}
            onChange={handleChange}
            placeholder="Pays"
            autocomplete="country-name"
          />
          {errors.town && <span style={{color: 'red'}}>{errors.town}</span>}           
          <label className="label-modal">Date de naissance :</label>
          <input
            className="input-modal"
            type="date" 
            name="date_of_birth"
            min="1900-01-01"
            max={currentDate}
            value={isProfilDataAmanded.date_of_birth}
            onChange={handleChange}
          />
          {errors.date_of_birth && <span style={{color: 'red'}}>{errors.date_of_birth}</span>}           
          <label className="label-modal">Adresse :</label>
          <input
            className="input-modal"
            type="address"
            name="address"
            value={isProfilDataAmanded.address}
            onChange={handleChange}
            placeholder="adresse"
            autocomplete="street-address"
          />
          {errors.address && <span style={{color: 'red'}}>{errors.address}</span>}           
          <label className="label-modal">Code postal :</label>
          <input
            className="input-modal"
            type="Number"
            name="postal_code"
            value={isProfilDataAmanded.postal_code}
            onChange={handleChange}
            placeholder="Code postal"
            autocomplete="postal-code"
          />
          {errors.postal_code && <span style={{color: 'red'}}>{errors.postal_code}</span>}           
          <label className="label-modal">Téléphone :</label>
          <input
            className="input-modal"
            type="Telephone"
            name="telephone_number"
            value={isProfilDataAmanded.telephone_number}
            onChange={handleChange}
            placeholder="Numéro de téléphone"
            autocomplete="tel"
          />
          {errors.telephone_number && <span style={{color: 'red'}}>{errors.telephone_number}</span>} 

          <label className="label-modal">Email :</label>
          <input
            className="input-modal"
            type="Email"
            name="email"
            value={isProfilDataAmanded.email}
            onChange={handleChange}
            placeholder="Email"
            required
            autocomplete="email"
          />
          {errors.email && <span style={{color: 'red'}}>{errors.email}</span>} 
          <label className="label-checbox-modal">
            <input
              className="checbox-modal"
              name="newletter"
              type="checkbox"
              checked={isProfilDataAmanded.newletter}
              onChange={handleChange}
            />
            Inscription à la NewLetter (restez informé des nouveaux services et
            prestations).
          </label>
          {errors.newletter && <span style={{color: 'red'}}>{errors.newletter}</span>} 
          <label className="label-checbox-modal">Mot de passe :</label>
          <p>(Pour valider la modification de vos informations, le mot de passe est obligatoire.)</p>
            <input
              className="input-modal"
              name="password"
              type="password"
              placeholder="Mot de passe"
              value={isProfilDataAmanded.password}
              onChange={handleChange}
              required
              autocomplete="current-password"
            />
          {errors.password && <span style={{color: 'red'}}>{errors.password}</span>} 
          {isButtonClicked ? (
            <div></div>
          ) : (
            <>
            <br/>
              <button
                className="buttonFormulaire"
                type="submit"
                disabled={isButtonClicked}
              >
                Modifier
              </button>
            </>
          )}
        </form>
      </div>
      )}
       
      </div>
  );
}

export default Profil;
