import React, {useRef, useEffect} from 'react';
import '../styles/pages/WebDevelopment.css'

function WebDevelopment() {
  const tittleWebDevelopment=useRef(null);

  useEffect(() => {
      tittleWebDevelopment.current.scrollIntoView({behavior:'smooth'});
  }, []);

  return (
    <div id="developpeur">
        <h2 ref={tittleWebDevelopment}>Développement Web </h2>
        <br/><br/>
        <p className="texteBlocIntro">Je suis ravi de vous expliquer en quoi consiste mon travail en tant que développeur Full Stack JavaScript, ainsi que les services que je peux vous apporter pour concrétiser vos projets en ligne. Mon objectif est de concrétiser vos idées et aspirations dans le monde numérique. Voici ce que vous pouvez attendre de mes services :</p>
        <br/><br/>
        <h4>Création d'Applications Web et de Sites Internet</h4>
        <br/>
        <p>Je suis spécialisé dans le développement d'applications web et de sites internet de haute qualité. De la conception initiale à la mise en ligne, je gère l'ensemble du processus pour vous assurer un produit final qui répond à vos besoins.</p>
        <br/>
        <h4>Création Professionnelle d'Applications Web/Mobile Javascript : </h4>
        <h5>React, HTML/CSS, Node.js et PostgreSQL</h5>
        <div>
          <img className='img-tech' src='/images/javascript.jpg' alt='Logo javascript'/>
          <img className='img-tech' src='/images/react.jpg' alt='Logo react'/>
          <img className='img-tech' src='/images/html5.jpg' alt='Logo html5'/>
          <img className='img-tech' src='/images/css3.png' alt='Logo css3'/>
          <img className='img-tech' src='/images/nodejs.jpg' alt='Logo nodejs'/>
          <img className='img-tech' src='/images/postgresql.png' alt='Logo nodejs'/>
        </div>
        <h4>Enregistrement de Nom de Domaine</h4>
        <br/>
        <p>Lorsque vous choisissez de travailler avec moi, l' enregistrement d' un nom de domaine approprié pour votre projet est compris dans la prestation, je m'ocuppe de tout. Cette étape est cruciale pour établir votre présence en ligne de manière professionnelle.</p>
        <br/>
        <h4>Hébergement et Mise en Ligne</h4>
        <br/>
        <p>Je prends en charge l'hébergement de votre site web ou de votre application, assurant ainsi qu'il soit accessible en ligne 24/7. La mise en ligne de votre projet est une étape que je gère avec soin pour garantir une transition fluide vers l'environnement de production.</p>
        <br/>
        <h4>Adaptation de la Technologie</h4>
        <br/>
        <p>Chaque projet est unique, et c'est pourquoi j'adapte les technologies et les bibliothèques que j'utilise en fonction de vos besoins spécifiques. Que vous souhaitiez une application web réactive, une expérience utilisateur fluide ou une solution robuste en backend, je choisirai les outils appropriés pour votre projet.</p>
        <br/>
        <h3>Tarification Personnalisée</h3>
        <br/>
        <p>Je comprends que chaque projet a ses propres exigences et contraintes budgétaires. C'est pourquoi je propose une tarification personnalisée pour chaque client. Nous discuterons de vos objectifs, de la portée du projet et de votre budget, puis nous établirons un plan sur mesure.
            
            En résumé, lorsque vous choisissez de travailler avec moi en tant que développeur Full Stack JS, vous obtenez une solution complète et sur mesure pour vos besoins en ligne. De la conception à la mise en ligne, en passant par la gestion de l'hébergement et de la technologie, je suis là pour vous accompagner tout au long du processus de développement.
            
            N'hésitez pas à me contacter pour discuter de votre projet spécifique. Je suis impatient de collaborer avec vous pour créer une expérience en ligne exceptionnelle.</p>
        <br/>
        <h4>Conditions de paiement</h4>
        <ul className="ulContentTexte">
            <li>Pour démarrer le projet, un acompte équivalent à 40% du coût total sera demandé à la signature du devis. Cette avance permet de couvrir les frais initiaux et de garantir votre engagement envers le projet.</li>
            <br/>
            <li>Le paiement du reste du montant (60%) sera dû à la fin du travail, lorsque le projet aura été réalisé à bien et que vous serez satisfait du résultat final.</li>
            <br/>
        </ul>
        <br/>
        <p>La raison derrière cette structure de paiement est de s'assurer que les deux parties, vous en tant que client et moi en tant que développeur, s'engagent pleinement dans le projet. L'acompte initial couvre les dépenses initiales et démontre votre sérieux dans la réalisation du projet. Le paiement final est effectué une fois que le travail est terminé selon les spécifications convenues.</p>
        <br/>
        <br/>
        <br/>
    </div>
  )
}

export default WebDevelopment