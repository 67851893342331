import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/_services/ProtectedRoute';
import useWindowSize from '../src/hooks/UseWindowSize';
import Header from './components/Header';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { Home, Horaire, Contact, ComputerHelp, WebDevelopment, PrivacyPolicies, LegalMentions, Page404, OnlineServicesPage, Cgu, ObtainCatchPhrasePage, TextToSpeechPage } from './pages/RouterPage';
import './App.css';
import { useAuth } from './contexte/Auth';
import ObligatoryLoginInfoPage from './pages/ObligatoryLoginInfoPage';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CookieConsentComp from './components/cookieConsent/CookieConsentComp';
import { AccountServicePublic } from './components/_services/AccountService';
import GoogleAnalyticsTraker from './components/_services/GoogleAnalyticsTraker';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PUBLIC);


function App() {

  const {isAuthenticated} = useAuth();
  const [isOpenMenuBurger, setIsOpenMenuBurger] = useState(false);
  const [isChangeAppBlockMenuPages, setIsChangeAppBlockMenuPages ] =useState(false);

  const [isChoiceOfCookies, setIsChoiceOfCookies] =useState()
  const [isCookieConsentAccepted, setIsCookieConsentAccepted] = useState();
  
  useEffect(() => {
    const consent = AccountServicePublic.getConsentCookies();
    const choiceConsent = AccountServicePublic.getVerifyChoiceConsentCookies();
    setIsCookieConsentAccepted(consent);
    setIsChoiceOfCookies(choiceConsent);
  }, []);

  const handleConsentChange = (consent) => {
    setIsCookieConsentAccepted(consent);
    AccountServicePublic.cookiesConsentChoice(true);
    setIsChoiceOfCookies(true);
  };
  const handleChangeChoiceConsent = ()=>{
    AccountServicePublic.resetCookieConsent();
    AccountServicePublic.cookiesConsentChoice(false);
    setIsChoiceOfCookies(false);
  };

  const windowSize = useWindowSize();
  const { width } = windowSize || {};
  
  useEffect(() => {
    setIsChangeAppBlockMenuPages(width >= 768);
  }, [width]);

  return (
    <div>
    <Elements stripe={stripePromise}>
      <Router>
      {isCookieConsentAccepted  ?(<div></div>):(<div></div>)}
      <GoogleAnalyticsTraker />
        <div className='parallax-image'></div>
            <div className='structuring-global-page'>
              <div id='app-bloc-header'>
                <Header />
              </div>
              <div className={isChangeAppBlockMenuPages ? 'app-bloc-menu-pages-medium':'app-bloc-menu-pages'}>
                <Menu isOpenMenuBurger={isOpenMenuBurger} setIsOpenMenuBurger={setIsOpenMenuBurger}  />
              <div className={isOpenMenuBurger ? 'current-page':'current-page-activ'}>
                <Routes>
                    <>                    
                    <Route path="/" exact element={<Home />} />
                    <Route path="/Horaire" element={<Horaire />} />
                    <Route path="/Contacter-BH-DevService" element={<Contact />} />
                    <Route path="/Services-en-ligne" element={isAuthenticated ?(<ProtectedRoute><OnlineServicesPage /></ProtectedRoute>):(<ObligatoryLoginInfoPage />)} />
                    <Route path="/Aide-informatique" element={isAuthenticated ?(<ProtectedRoute><ComputerHelp /></ProtectedRoute>):(<ObligatoryLoginInfoPage />)} />
                    <Route path="/Obtenir-un-slogan" element={isAuthenticated ?(<ProtectedRoute><ObtainCatchPhrasePage/></ProtectedRoute>):(<ObligatoryLoginInfoPage />)} />
                    <Route path="/Transformer-un-texte-en-audio" element={isAuthenticated ?(<ProtectedRoute><TextToSpeechPage /></ProtectedRoute>):(<ObligatoryLoginInfoPage />)} />
                    <Route path="/développement-web" element={<WebDevelopment />} />
                    <Route path="/*" element={<Page404 />} />
                    <Route path="/PrivacyPolicies" element={<PrivacyPolicies />} />
                    <Route path="/LegalMentions" element={<LegalMentions />} />
                    <Route path="/CGU" element={<Cgu />} />
                    </>
                </Routes>
              </div>
              </div>
              <div id='app-bloc-footer'>
                <Footer  setIsOpenMenuBurger={setIsOpenMenuBurger} onConsentChangeChooce={handleChangeChoiceConsent}/>
              </div>
            </div>
      </Router>
    </Elements>
    {isChoiceOfCookies?(
      <div></div>
      ):(
        <div>
          <CookieConsentComp onConsentChange={handleConsentChange}/>
      </div>
      )}    
    </div>
  );
}

export default App;
