import React, { createContext, useContext, useState,useEffect  } from 'react';
import { AccountServicePublic } from '../components/_services/AccountService';

const AuthContext = createContext();
// Créer une variable pour conserver la référence à la fonction setIsAuthenticated
let externalSetIsAuthenticated = () => {};
export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AccountServicePublic.isLogged);
  // Utiliser useEffect pour mettre à jour la référence externe lors de l'initialisation
  useEffect(() => {
    externalSetIsAuthenticated = setIsAuthenticated;
}, []);
    const login = (res) => {
        setIsAuthenticated(true);
        //enregistrement du token dans le local storage
        AccountServicePublic.saveToken(res.data.accessToken);
    
    };

    const logout = () => {
        setIsAuthenticated(false);
        // Supprimer Token, Appel d'API de déconnexion, etc...
           //enregistrement du refreshToken dans les cookies et enregistrement du token dans le local storage
           AccountServicePublic.logout();
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
// Exposer une fonction pour changer l'état isAuthenticated de l'extérieur
export const setAuthExternally = (status) => {
    externalSetIsAuthenticated(status);
};