import { useState } from 'react';
import * as yup from 'yup';

// Hook pour la validation du formulaire
function useValidation(schema) {
  const [errors, setErrors] = useState({});
  
 
  //Shema :
    // Définir le schéma de validation avec yup
   const validationSchema = yup.object().shape({
    //Champs obligatoire :
    name: yup
      .string()
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'Le nom ne doit contenir que des lettres')
      .required('Le nom est requis'),
    first_name: yup
      .string()
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'Le prénom ne doit contenir que des lettres')
      .required('Le prénom est requis'),
    email: yup
      .string()
      .email('Adresse e-mail invalide')
      .required('Email est requis'),
    password: yup.string()
        .required('Le mot de passe est requis')
        .min(12, 'Le mot de passe doit contenir au moins 12 caractères')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*['/_\-`~^.[\]$()*+?|{}@\\])[a-zA-Z0-9\S]{12,}$/,
        'Le mot de passe doit contenir 12 caractères minimun, au moins une majuscule, une minuscule, un chiffre et un caractère spécial'),
    //Champs non obligatoire :
    country: yup
      .string()
      .nullable()
      .notRequired(),
    date_of_birth: yup
      .date()
      .nullable()
      .notRequired()
      .typeError('Le format de la date de naissance attendu : jj/mm/aaaa')
      .transform((value, originalValue) => 
      originalValue === '' ? null : value
    ),
    address: yup
      .string()
      .nullable()
      .notRequired(),
    postal_code:  yup
      .number()
      .nullable()
      .notRequired()
      .typeError('Le code postal doit être un nombre valide')
      .transform((value, originalValue) => 
      originalValue === '' ? null : value
    ),
    telephone_number: yup
      .string()
      .nullable()
      // eslint-disable-next-line no-useless-escape
      .matches(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Numéro de téléphone invalide')
      .notRequired()
      .typeError('Le numéro de téléphone doit être un nombre valide')
      .transform((value, originalValue) => 
      originalValue === '' ? null : value
    ),
    newletter: yup
    .boolean()
    .notRequired(),
  });

  const validate = async (data) => {
    setErrors({}); // réinitialiser les erreurs précédentes

    try {
      await validationSchema.validate(data, { abortEarly: false });
      return true; // la validation a réussi
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors = {};
        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors); // définir les messages d'erreur de validation
      }
      return false; // la validation a échoué
    }
  };

  return [errors, validate];
}

export default useValidation;
