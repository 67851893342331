import React from 'react';
import ComputerHelphAI from '../components/serviceOnline/ComputerHelphAI';
import '../styles/pages/ComputerHelp.css';

function ComputerHelp() {

  return (
    
    <div>
      <ComputerHelphAI />
    </div>

  )
}

export default ComputerHelp;