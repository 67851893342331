import React, { useState } from 'react';
import '../../styles/components/Buttons.css';
import UserLogin from '../formLoginContact/UserLogin';
import ReactGA from 'react-ga4';

function LoginButton() {
    const [showLogin, setShowLogin] = useState(false);

    const handleClick = () => {
        setShowLogin(true);

        // Suivi de l'événement avec Google Analytics GA4
        ReactGA.event("login_click", {
            category: "Utilisateur",
            label: "Modal de Connexion"
        });
    }
    const handleClose = () => {
        setShowLogin(false);
    }

    return (
        <div>
            <button className='buttons-login' onClick={handleClick}>Se connecter</button>
            {showLogin ?( <UserLogin onClose={handleClose} />):(<div></div>)}
        </div>
    );
}

export default LoginButton;
