import React,{useEffect, useRef, useState} from 'react';
import AudioPlay from '../audio/AudioPlay';
import ReactGA from 'react-ga4';
import '../../styles/components/TextToSpeech.css';
import swal from 'sweetalert';
import { UserService } from '../_services/UserService';
import Loading from '../icone/Loading';

function TextToSpeech() {
    //state quality
    const [isQualitySelected, setIsQualitySelected] =useState(null);
    const [isStandardQuality, setIsStandardQuality] =useState(false);
    const [isHdQuality, setIsHdQuality] =useState(false);
 
    //state format
    const [isUserFormatSelected, setIsUserFormatSelected]= useState(null);
    const [isMp3Selected , setIsMp3Selected] =useState(false);
    const [isAACSelected , setIsAACSelected] =useState(false);
    const [isOpusSelected , setIsOpusSelected] =useState(false);
    const [isFLACSelected , setIsFLACSelected] =useState(false);
    
    //state voice
    const [isVoiceSelected, setIsVoiceSelected] =useState(null);
    const [isAlloyVoiceSelected, setIsAlloyVoiceSelected] =useState(false);
    const [isEchoVoiceSelected, setIsEchoVoiceSelected] =useState(false);
    const [isFableVoiceSelected, setIsFableVoiceSelected] =useState(false);
    const [isNovaVoiceSelected, setIsNovaVoiceSelected] =useState(false);
    const [isOnyxVoiceSelected, setIsOnyxVoiceSelected] =useState(false);
    const [isShimmerVoiceSelected, setIsShimmerVoiceSelected] =useState(false);

    //state form to send
    const [userInput, setUserInput] = useState('');
   //afte send
    const [isLoading, setIsLoading] =useState(false);

    const handleInputChange = (e) => {
        if (e.target.value.length <= 4090) {
          setUserInput(e.target.value);
        }
      }

    const qualitySelected =(format)=>{
        setIsQualitySelected(format);
        setIsStandardQuality(format === 'standard');
        setIsHdQuality(format === 'hd');
    };  

    const voiceSelected=(format)=>{
        setIsVoiceSelected(format);
        setIsAlloyVoiceSelected(format === 'alloy');
        setIsEchoVoiceSelected(format === 'echo');
        setIsFableVoiceSelected(format === 'fable');
        setIsNovaVoiceSelected(format === 'nova');
        setIsOnyxVoiceSelected(format === 'onyx');
        setIsShimmerVoiceSelected(format === 'shimmer');
    };

    const textForLoading="Veuillez patienter le temps de la création de votre fichier audio. 😊";
    const [isNewQuestion, setIsNewQuestion]=useState(false);
    const [isPrompt, setIsPrompt] =useState(false);

    const tittleTextToSpeech =useRef(null);
    useEffect(() => {
      tittleTextToSpeech.current.scrollIntoView({behavior:'smooth'});
    }, []);

    const responseTextToSpeech = useRef();


    const formatSelected = (format) => {
        setIsUserFormatSelected(format);
        setIsMp3Selected(format === 'mp3');
        setIsOpusSelected(format === 'opus');
        setIsAACSelected(format === 'aac');
        setIsFLACSelected(format === 'flac');
      }; 
    //Fonction affichage texte quality
    const getDescriptionQualityFormat =()=>{
        switch (true) {
            case isHdQuality:
                return 'Qualité audio élevée, taille de fichier plus grande. (Coût 2 crédits)'
            case isStandardQuality:
                return 'Qualité audio standard, taille de fichier réduite. (Coût 1 crédit)'
            default:
                return 'Qualité du fichier audio non selectionné';
        }
    };     
    // Fonction pour obtenir le texte descriptif en fonction du format sélectionné
  const getDescriptionTextFormat = () => {
    switch (true) {
      case isMp3Selected:
        return 'Format audio compressé qui réduit la taille des fichiers tout en conservant une qualité sonore décente. Très populaire pour le stockage et le partage de musique en ligne.';
      case isOpusSelected:
        return 'Un codec audio numérique développé pour offrir une qualité sonore élevée tout en maintenant une faible latence. Souvent utilisé pour la voix sur IP (VoIP) et le streaming audio en temps réel.';
      case isAACSelected:
        return 'Un format de compression audio standard qui offre une meilleure qualité sonore par rapport au MP3 à des débits binaires similaires. Souvent utilisé dans les fichiers audio de qualité supérieure et les services de streaming.';
      case isFLACSelected:
        return "Un format audio sans perte, ce qui signifie qu'il compresse les fichiers sans perte de qualité audio. Il est souvent utilisé pour l'archivage de musique de haute qualité et est pris en charge par de nombreux lecteurs multimédias.";
      default:
        return 'Auncun format sélectionné.';
    }
  };


      // Fonction pour obtenir le texte descriptif en fonction du format sélectionné
  const getDescriptionTextVoice = () => {
    switch (true) {
        case isAlloyVoiceSelected:
            return 'ALLOY: Une voix moderne et polyvalente, parfaite pour donner vie à une variété de contenus avec une clarté et une expressivité exceptionnelles.';
        case isEchoVoiceSelected:
            return "ECHO: Une voix qui ajoute une dimension spatiale à l'audio, avec des nuances réfléchies qui amplifient l'impact du message.";
        case isFableVoiceSelected:
            return 'FABLE: Une voix captivante et engageante, idéale pour les récits et les histoires qui transportent les auditeurs dans un monde imaginatif.';
        case isNovaVoiceSelected:
            return 'NOVA: Une voix dynamique et énergique, parfaitement adaptée pour des annonces ou des contenus qui nécessitent une livraison percutante.';
        case isOnyxVoiceSelected:
            return "ONYX: Une voix profonde et riche qui évoque une sensation de sérieux et d'autorité, idéale pour des discours ou des messages importants.";
        case isShimmerVoiceSelected:
            return "SHIMMER: Une voix qui ajoute une touche distinctive, avec une légère étincelle ou un éclat, apportant une nuance particulière à l'audio.";
    default:
    return 'Aucune voix selectionnée.';
    }
    };

    const handleSubmit = async () => {
        try {

            // Suivi de l'événement avec Google Analytics
            ReactGA.event('send_text_TextToSpeech',{
                category: 'Utilisateur',
                label: 'Service TextToSpeech'
            });
            //get loading screen
            setIsLoading(true);

            //Vérification si l'utilisateur a bien selectionné (quality/format/voice) et si il y a du texte
            if(isQualitySelected===null){
                swal("Veuillez choisir la qualité du fichier audio souhaité.");
                setIsLoading(false);
                return;
            }
            if(isVoiceSelected===null){
                swal("Veuillez faire un choix de voix avant d'envoyer.");
                setIsLoading(false);
                return;
            }
            if(isUserFormatSelected===null){
                swal("Veuillez choisir le format avant d'envoyer.");
                setIsLoading(false);
                return;
            }
            if(userInput.length<1){
                swal("Veuillez ajouter le texte à retranscrire en audio.");
                setIsLoading(false);
                return;
            }
            if(userInput.length>4090){
                swal("Veuillez ne pas dépasser le nombre de caractère autorisé du texte à retranscrire en audio.");
                setIsLoading(false);
                return;
            }

            const response = await UserService.postTextToSpeech({isQualitySelected, isUserFormatSelected, isVoiceSelected, userInput})
            try {
                if(response.status ===200){
                    const fileName = response.data;
                    fetch(`${process.env.REACT_APP_API_URL}/getAudioFile/${fileName}`)
                    .then(response => {
                        if (!response.ok) {
                          throw new Error(`Erreur HTTP ${response.status}`);
                        }
                        return response.blob(); 
                      })
                      .then(blob => {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = URL.createObjectURL(blob);
                        downloadLink.download = fileName;
                        downloadLink.click();
                        setIsPrompt(true);
                        setIsLoading(false);
                        setIsNewQuestion(true);
                      responseTextToSpeech.current.scrollIntoView({behavior:'smooth'});
                    })
                      .catch(error => {
                        console.error('Erreur lors de la récupération du fichier :');
                        setIsLoading(false);
                      });
                }
                if(response.status===403){
                    swal("Veuillez ajouter des crédits si vous souhaitez utiliser ce service.");
                    setIsLoading(false);
                }
                if(response.status===422){
                    swal("Vérifier votre saisie/choix avant d'envoyer.");
                    setIsLoading(false);
                }
            } catch (error) {
               swal("Erreur lors de la récupération du fichier audio");
               setIsLoading(false);
            }
        } catch (error) {
            if(error !=="Network Error"){
                if(error.response.status === 403){
                    swal("Veuillez ajouter des crédits si vous souhaitez utiliser ce service.")
              }
              else if(error.response.status === 422){
                swal("Le choix du model ne correspond pas.")
              }
              else if(error.response.status){
                swal("Une dépence externe ne répond pas,veuillez rééssayer plus tard.")
              }  
              else{
                swal("Erreur lors de la communication avec le server, veuillez rééssayer plus tard.")
            }
        }
            setIsLoading(false);
        }
    };
    const askQuestion =()=>{
        setIsVoiceSelected(null);
        setIsUserFormatSelected(null);
        setUserInput("");
        setIsStandardQuality(false);
        setIsHdQuality(false);
        setIsAlloyVoiceSelected(false);
        setIsMp3Selected(false);
        setIsAACSelected(false);
        setIsOpusSelected(false);
        setIsFLACSelected(false);
        setIsEchoVoiceSelected(false);
        setIsFableVoiceSelected(false);
        setIsNovaVoiceSelected(false);
        setIsOnyxVoiceSelected(false);
        setIsShimmerVoiceSelected(false);
        setIsPrompt(false);
        setIsNewQuestion(false);
        tittleTextToSpeech.current.scrollIntoView({behavior:'smooth'});
    };
  return (
    <div>
        <div id="TextToSpeech">
            <h2 ref={tittleTextToSpeech}>TextToSpeech</h2>
                <h3> à votre service</h3>
                <p ref={responseTextToSpeech} ></p>
                <br/>
        {isLoading ?(<div>
                        <Loading textProps={textForLoading}/>
                    </div>
                    ):(
                    <div>
                  <h4>Bienvenue dans ce service innovant de conversion de texte en audio. Transformez instantanément vos mots en fichiers audio de haute qualité grâce à notre technologie avancée. Avec la possibilité de choisir parmi six voix distinctes. Ce service prend en charge une variété de formats de sortie pour s'adapter à vos besoins spécifiques, que ce soit pour des présentations professionnelles, des projets créatifs, des podcasts, ou autre. Facile à utiliser, découvrez comment gagner du temps tout en offrant une expérience audio exceptionnelle à votre audience. Libérez votre créativité avec notre service de transformation texte en audio.</h4>

     {isPrompt ?(
            <div>

            </div>
            ):(
            <div>
                <h5>Renseignez le questionnaire ci-dessous, et vous obtiendrez votre texte en format audio.</h5>
                <br/>
                <br/>
                <h6>Choisissez de la qualité du format souhaité :</h6>
                <br/>
                <div className='section-bloc-choose'>
                    <div className='bloc-format' onClick={()=>qualitySelected("standard")}>
                        <button className={`button-format-choose ${isStandardQuality ? 'button-format-choose-selected' : ''}`}>std</button>    
                    </div>
                    <div className='bloc-format' onClick={()=>qualitySelected("hd")}>
                        <button  className={`button-format-choose ${isHdQuality ? 'button-format-choose-selected' : ''}`}>HD</button>    
                    </div>
                </div>
                    <p>{getDescriptionQualityFormat()}</p>
      
            <div>
                <h6>Choisissez la voix :</h6>
                <br/>
                <div className='section-bloc-choose'>
                    <div className={`bloc-voice ${isAlloyVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("alloy")}>    
                        <AudioPlay audioSrc=".\son\voixAlloy.wav"  />           
                    </div>
                    <div className={`bloc-voice ${isEchoVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("echo")}>
                        <AudioPlay audioSrc=".\son\voixEcho.wav" />          
                    </div>
                    <div className={`bloc-voice ${isFableVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("fable")}>
                        <AudioPlay audioSrc=".\son\voixFable.wav" />          
                    </div>
                    <div className={`bloc-voice ${isNovaVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("nova")}>
                        <AudioPlay audioSrc=".\son\voixNova.wav" />          
                    </div>
                    <div className={`bloc-voice ${isOnyxVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("onyx")}>
                        <AudioPlay audioSrc=".\son\voixOnyx.wav" />          
                    </div>
                    <div className={`bloc-voice ${isShimmerVoiceSelected ? 'bloc-voice-selected' : ''}`} onClick={()=>voiceSelected("shimmer")}>
                        <AudioPlay audioSrc=".\son\voixShimmer.wav" />          
                    </div>
                </div>                
                <p>{getDescriptionTextVoice()}</p>
            </div>
            <div>
                <h6>Choisissez le format souhaité :</h6>
                <br/>
                <div className='section-bloc-choose'>
                <div className='bloc-format' onClick={()=>formatSelected("mp3")}>
                        <button className={`button-format-choose ${isMp3Selected ? 'button-format-choose-selected' : ''}`}>MP3</button>    
                    </div>
                    <div className='bloc-format' onClick={()=>formatSelected("opus")}>
                        <button  className={`button-format-choose ${isOpusSelected ? 'button-format-choose-selected' : ''}`}>OPUS</button>    
                    </div>
                    <div className='bloc-format' onClick={()=>formatSelected("aac")}>
                        <button  className={`button-format-choose ${isAACSelected ? 'button-format-choose-selected' : ''}`}>AAC</button>    
                    </div>
                    <div className='bloc-format' onClick={()=>formatSelected("flac")}>
                        <button  className={`button-format-choose ${isFLACSelected ? 'button-format-choose-selected' : ''}`}>FLAC</button>    
                    </div>
                </div>
                    <p>{getDescriptionTextFormat()}</p>
                    <br/>
                <div className='bloc-textarea-charCount'>
                <label>Saisissez le texte à transformer en voix :</label>
                <textarea className='textarea' value={userInput} onChange={handleInputChange} />
                <span id="charCount" className='text-compte'>Caractères: {userInput.length}/4090</span>
    
                </div>  
                <div>
                  <button className='buttonFormulaire' onClick={handleSubmit}>Envoyer</button>                 
                </div>  
                <div>
            </div>
            </div>
            </div>)}

        </div>
        )}
        {isNewQuestion?(
                <div>
                <p>Votre fichier a bien été télécharger.</p>
                  <button className='buttonFormulaire' onClick={askQuestion}>Nouveau audio</button>
                </div>
              ):(<div></div>)}
  
        </div>
    </div>
  )
}

export default TextToSpeech;