import React from 'react';
import { useAuth } from '../../contexte/Auth';

import UserLogin from '../formLoginContact/UserLogin';

function ProtectedRoute({ children }) {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
        return <UserLogin />;
    }

    // Renvoyez les enfants (le composant protégé) si l'utilisateur est connecté
    return children;
}

  
  export default ProtectedRoute;