import React, { useState, useRef, useEffect } from 'react';
import ContactForm from '../components/FormContact/ContactForm';
import '../styles/pages/Contact.css';

function Contact() {
  const [contactPreference, setContactPreference] = useState('');
  const [isFormValidated, setIsFormValidated] = useState(false);

  const tittleContact=useRef(null);

  useEffect(() => {
      tittleContact.current.scrollIntoView({behavior:'smooth'});
  }, []);
  
  const handleValidationChange = (isValidated) => {
      setIsFormValidated(isValidated);
  };

  const handlePreferenceChange = (preference) => {
      setContactPreference(preference);
  };

  return (

<div id="contact">  
  <h2 ref={tittleContact}>Formulaire de Contact</h2>
  {isFormValidated?(
    <div>
      <h4>Votre formulaire a bien été envoyé :</h4>
      <h5>Vous serez contactez par {contactPreference} comme vous l'avez précisé dans le formulaire.</h5>
    </div>
    ):(
    <div>
    <br/><br/>
    <p className="texteBlocIntro">Je suis heureux de vous offrir une option de contact pratique et rapide ! Si vous préférez me joindre via <a className="ancreExterne" href="https://wa.me/message/OABZQR5HRFP3M1" target="_blank" rel="noopener noreferrer"> WhatsApp</a> pour discuter de vos besoins ou poser des questions sur mes services, n'hésitez pas à le faire. Vous pouvez simplement m' envoyer un message en indiquant le service que vous souhaitez et en synthétisant votre demande. Je serais ravi de vous assister rapidement et efficacement. Votre satisfaction est ma priorité, et je suis là pour vous aider de la manière qui vous convient le mieux</p>
  <h3>Veuillez remplir ce formulaire :</h3>
   <div className='bloc-Contact'>
    <ContactForm 
        onValidationChange={handleValidationChange} 
        onPreferenceChange={handlePreferenceChange} 
    />
</div>
  </div>)}
</div>
  );
}

export default Contact;
