import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Footer.css'
import ResetConsentUser from './buttons/ResetConsentUser';

function Footer({ setIsOpenMenuBurger,onConsentChangeChooce }) {

  const closeMenu=()=>{
   setIsOpenMenuBurger(false);
  }
  const changeChoiceConsent=()=>{
    onConsentChangeChooce();
  }
  return (
      <footer className='footer-global'>
        <div  className='bloc-footer'>
            <div id="socialReseau">
              <div>
              <div>
                <h6>Réseaux sociaux et Contact</h6>
              </div>
              <div>
                <ul className="ulFooter">
                    <li id="reseauFacebook" className="footerLi"><a href="https://www.facebook.com/profile.php?id=61550894517646" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                    <li id="reseauWhatsApp" className="footerLi"><a href="https://wa.me/message/OABZQR5HRFP3M1" target="_blank" rel="noopener noreferrer">WhatsApp</a></li>
                </ul>
              </div>
              </div>
            </div>
            <div id="informationBloc">
              <div>
              <div>
                  <h6>Informations importantes</h6>
              </div>
              <div>
                  <nav className="ulFooter">
                    <Link to="/LegalMentions" className='App-link-footer' onClick={()=>closeMenu()}>Mentions légales</Link>
                    <Link to="/PrivacyPolicies" className='App-link-footer' onClick={()=>closeMenu()}>Politique de confidentialité</Link>
                    <div className="tooltip">
                    <ResetConsentUser  onConsentChangeChooce={changeChoiceConsent}/>
                    <span className="tooltiptext">Paramétrer vos cookies</span>
                  </div>
                  </nav>
              </div>
              </div>
            </div>   
        </div>
        <br/>
        <br/>
        <div>
            <nav className='copryright'>© 2023 [BH-DevService]</nav>
        </div>
      </footer>
  )
}

export default Footer