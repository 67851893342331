import React,{useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/Horaire.css';

function Horaire() {
  
  const tittleHoraire=useRef(null);

  useEffect(() => {
      tittleHoraire.current.scrollIntoView({behavior:'smooth'});
  }, []);
  
  return (
<div id="horaire">
    <h2 ref={tittleHoraire}>Horaires et disponibilités</h2>
    <br/><br/>
    <p className="texteBlocIntro">Avant de vous dévoiler les horaires et disponibilités, permettez-moi de partager une petite anecdote avec vous.</p>
    <br/>
    <p className="texteBlocIntro">En tant que travailleur indépendant, je jongle constamment entre les lignes de code et les demandes de mes précieux clients. On dit souvent que je parle couramment "langage machine", mais parfois, même moi, je me perds dans les fils du téléphone.</p> 
    <br/>    
    <p className="texteBlocIntro">Pour garantir une réponse rapide et de qualité, je vous encourage à initier notre première interaction via la partie <Link className='link-to-contact' to="/Contacter-BH-DevService">Contact</Link> de mon site. Cette méthode privilégiée me permet de maintenir une efficacité accrue dans mon travail tout en vous offrant le meilleur service possible. En cas de préférence ou de besoin, une alternative serait de communiquer par email, via WhatsApp, par téléphone ou même en visioconférence. Une fois ce premier contact établi par écrit, je me tiens à votre disposition pour discuter de vos besoins plus en détail lors de notre entretien ultérieur, que ce soit par téléphone ou en visioconférence.</p>
    <br/>    
    <p className="texteBlocIntro">Alors pourquoi un premier contact par écrit ? Eh bien, laissez-moi vous expliquer.  Imaginez-moi en train de jongler avec des lignes de code complexes, concentré tel un funambule sur son fil tendu. À ce moment critique, un coup de fil serait comme un vent impromptu menaçant l'équilibre précaire de cette performance numérique.</p>
    <br/>    
    <p className="texteBlocIntro">Merci de votre compréhension, et n'hésitez pas à me contacter pour toute question ou demande. Je suis là pour vous servir avec un sourire derrière chaque ligne de code !</p>                         
    <br/>
    <br/>
    <br/>
    <p className='text-contact-horaire'>
    Vous pouvez me contacter initialement par email ou via WhatsApp. Mon horaire de travail est du lundi au vendredi, de 8h à 18h. Je m'engage à répondre à vos messages sous 24 à 48 heures afin de convenir d'un entretien téléphonique. Cela nous permettra de discuter de vos besoins avant d'établir un devis personnalisé.
    </p>
    <br/><br/>
</div>
  )
}

export default Horaire;