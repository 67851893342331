import Cookies from 'js-cookie';



let saveToken=(token)=>{
    localStorage.setItem('accessToken', token);
}

let logout =()=>{
    localStorage.removeItem('accessToken');
}    
let isLogged = () => {
    const token = localStorage.getItem('accessToken'); 
    return !!token && token !== "undefined";
}

let getConsentCookies=()=>{
    const consentCookies = Cookies.get('user_consent');
    if(consentCookies===undefined || consentCookies==="false"){
        return false;
    }else{
        return true;
    }

}


let resetCookieConsent=()=>{
    Cookies.remove('user_consent');
}

let cookiesConsentChoice =(choice)=>{
   Cookies.set('user_consent_choice', choice);
}  

let getVerifyChoiceConsentCookies =()=>{
    const consentCookiesChoice = Cookies.get('user_consent_choice');
    if(consentCookiesChoice==="true" || consentCookiesChoice==="false"){
        return true;
    }else{
        return false;
    }
}

export const AccountServicePublic={
     saveToken, logout, isLogged, getConsentCookies,resetCookieConsent, cookiesConsentChoice, getVerifyChoiceConsentCookies
}