import  axiosInstance from "./InterceptorToken";

const postFormContact = async (contactInfo) => {
    try {
        const response = await axiosInstance.post(`/postFormContact`, contactInfo);
        return { data: response.data, status: response.status }; 
    } catch (error) {
        return { error: error.response || error };    
    }
}

const  postApiService =  (userInput, operatingSystem) => {
    return axiosInstance.post(`/postApiService`, userInput, operatingSystem);
}

const  postObtainCatchPhraseIA =  (userInputDescription, userInputName, userInputActivity, userInputPublicTarget, model) => {
    return axiosInstance.post(`/postObtainCatchPhraseIA`, userInputDescription, userInputName, userInputActivity, userInputPublicTarget, model);
}

const  postTextToSpeech =  (isQualitySelected, isUserFormatSelected, isVoiceSelected, userInput) => {
    return axiosInstance.post(`/textToSpeech`, isQualitySelected, isUserFormatSelected, isVoiceSelected, userInput);
}

const postLoginUser = async (infoLogin) => {
    try {
        const response = await axiosInstance.post(`/login`,infoLogin);
        return { data: response.data, status: response.status }; 
    } catch(error) {
        return { error: error.response || error };    }
    }

const postRecoveryPassword =async(email)=>{
    try {
        const response = await axiosInstance.post(`/recoveryPassword`, email);
        return { data: response.data, status: response.status }; 
    } catch (error) {
        return { error: error.response || error };    
    }
}

const getRefreshToken= async ()=>{
    return await axiosInstance.get(`/refreshToken`);
}
const postRegisterUser =async  (infoRegister) => {
    try {
        const response = await axiosInstance.post(`/register`, infoRegister);
        return { data: response.data, status: response.status }; 
    } catch(error) {
        return { error: error.response || error };
    }
}

const getProfilUser= async ()=>{
    try {
        const response =await axiosInstance.get(`/profilUser`);
        return {data: response.data, status: response.status};
    } catch(error) {
        return { error: error.response || error };
    }
}
const updatePassword= async(data)=>{
    try {
        const response =await axiosInstance.post(`/updatePassword`, data);
        return {data: response.data, status: response.status};
    } catch (error) {
        return { error: error.response || error };
    }
}
const updateProfilUser= async (data)=>{
    try {
        const response =await axiosInstance.patch(`/updateProfilUser`, data);
        return {data: response.data, status: response.status};
    } catch(error) {
        return { error: error.response || error };
    }
}

const rechargeCreditWithStripe= async (data)=>{
    try {
        const response =await axiosInstance.post(`/rechargeCredit`, data);
        return {data: response.data, status: response.status};
    } catch (error) {
        return { error: error.response || error };
    }
}

const getTransactionHistory =async()=>{
    try {
        const response = await axiosInstance.get(`/getTransactionHistory`);

        return {data: response.data, status:response.status}
    } catch(error) {
        return { error: error.response || error };
    }
}
const getHistoryCreditUsed =async()=>{
    try {
        const response = await axiosInstance.get(`/getHistoryCreditUsed`);

        return {data: response.data, status:response.status}
    } catch(error) {
        return { error: error.response || error };
    }
}

export const UserService =  {
    postFormContact,
    postApiService,
    postObtainCatchPhraseIA,
    postTextToSpeech,
    postLoginUser,
    postRecoveryPassword,
    postRegisterUser,
    getRefreshToken,
    getProfilUser,
    updatePassword,
    updateProfilUser,
    rechargeCreditWithStripe,
    getTransactionHistory,
    getHistoryCreditUsed
};
