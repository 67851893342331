import React , { useState} from 'react';
import '../../styles/components/Buttons.css';
import Profil from '../../pages/ProfilPage';


function ProfilUserButton(props) {
    const [showProfil, setShowProfil] = useState(false);


    const handleClick = () => {
        setShowProfil(true);
    }
    const handleClose = () => {
        setShowProfil(false);
    }
  return (
    <div>
    <button className={`buttons-profil ${props.isCustomStyle ? 'custom-style' : ''}`} onClick={handleClick}>Mon Profil</button>
    {showProfil ?( <Profil onClose={handleClose} initialSelectedSection={props.initialSelectedSection}/>):(<div></div>)}
</div>  )
}

export default ProfilUserButton;