import axios from 'axios';
import swal from 'sweetalert';
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

import { setAuthExternally } from '../../contexte/Auth';

import { AccountServicePublic } from './AccountService';
const base_Url =process.env.REACT_APP_API_URL;


const axiosInstance = axios.create({
    baseURL: base_Url,
    withCredentials: true,
});

const axiosInstanceRefreshToken = axios.create({
    baseURL: base_Url,
    withCredentials: true,
});


axiosInstance.interceptors.request.use(
  async config => {

    if(config.url==='/login' ||config.url==='/postFormContact'||config.url==='/register'||config.url==='/recoveryPassword'){
        return config;
    }
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {       
        swal("Veuillez créer un compte, ou vous connectez pour utiliser ce service");
    }
    const isTokenValid=(accessToken)=>{
        const decodedToken = jwtDecode(accessToken);
        const isExpired = dayjs().isAfter(dayjs.unix(decodedToken.exp));
        if (!isExpired) {
            return true;
        }else {
            return false;
        }
    }
    if (accessToken && isTokenValid(accessToken)) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }else{
      await  axiosInstanceRefreshToken.post('/refreshToken')
        .then(response => {
           const newAccessToken = response.data.accessToken;
           AccountServicePublic.saveToken(newAccessToken);
           config.headers['Authorization'] = `Bearer ${newAccessToken}`;

        })
        .catch(error => {
            swal("Une erreur est survenue, veuillez rééssayer ou me contacter");
        });
                
    }   

    return config;
},
error => {
    return Promise.reject(error);
}
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if ((error.response.data && (error.response.data.message === "Aucun token reçu" || error.response.data.message === "Refresh token not found"))|| error.response.status === 422) {
                setAuthExternally(false); 
                
                AccountServicePublic.logout();
                
                swal("Votre session a expiré, veuillez-vous reconnecter.")
                    .then(() => {            
                        window.location.replace('/');
                    });
                return Promise.reject(error); 
            } else {
                return Promise.reject(error);
            }
        } else if (error.message && error.message === 'Network Error') {
            swal("Le server est hors-service, veuillez rééssayer plus tard.");
            return Promise.reject(error.message);
        } else {
            return Promise.reject(error);
        }
    }
);



export default axiosInstance;
