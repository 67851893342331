import React from 'react'
import LoginButton from '../components/buttons/LoginButton'
import RegisterButton from '../components/buttons/RegisterButton'

function ObligatoryLoginInfoPage() {
  return (
    <div>
      <h2>Identification requise pour entrer</h2>
         <h4>Vous devez vous identifiez pour pouvoir accéder à ce service.</h4>
        <p> ↓ ↓ ↓ ↓ ↓ ↓ </p>
         <LoginButton />
         <h4>Ou Inscrivez-vous dès maintenant et profitez de vos crédits offerts pour découvrir ce service en exclusivité et d'autres qui viendront par la suite !</h4>
         <p> ↓ ↓ ↓ ↓ ↓ ↓ </p>
         <RegisterButton />
    </div>
  )
}

export default ObligatoryLoginInfoPage