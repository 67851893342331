import React,{useRef, useEffect} from 'react';
import '../styles/pages/LegalMentions.css'

function LegalMentions() {
  const tittleMentions=useRef(null);

  useEffect(() => {
      tittleMentions.current.scrollIntoView({behavior:'smooth'});
  }, []);
  return (
    <div id='legalMentions'>
       <h2 ref={tittleMentions}>Les mentions légales</h2>
            <br/>
            <h3>Partie entreprise</h3>
            <br/><br/>
                <h5>Nom de l'entreprise</h5>
                <p><strong>BH-DevService</strong></p><br/>
                <h5>Adresse du siège social </h5>
                <p>71140 Bourbon-lancy</p><br/>
                <h5>Numéro de téléphone</h5>
                <p>+33 7 56 94 06 85</p><br/>
                <h5>Adresse e-mail de contac</h5>
                <p><a href="mailto:hugo.brancatelli@bh-devservice.com">hugo.brancatelli@bh-devservice.com</a></p><br/>
                <h5>Numéro de TVA intracommunautaire</h5>
                <p>FR 22883671232</p><br/>
                <h5>Numéro d'enregistrement de l'entreprise</h5>
                <p>883671232 00014</p><br/>
                <h5>Nom du directeur de la publication</h5>
                <p><strong>Hugo Brancatelli</strong></p><br/>
        <h3>Partie Hébergeur</h3>
        <br/><br/>
            <h5>Nom de l'hébergeur</h5>
            <p><strong>o2switch</strong></p><br/>
            <h5>Adresse du siège social</h5>
            <p>RCS Clermont Ferrand</p><br/>
            <h5>Téléphone</h5>
            <p>04 44 44 60 40</p><br/>          
    </div>
  )
}

export default LegalMentions