import React,{useRef, useEffect} from 'react';
import '../styles/pages/Cgu.css';

function Cgu() {
    const tittleCgu=useRef(null);

    useEffect(() => {
        tittleCgu.current.scrollIntoView({behavior:'smooth'});
    }, []);

  return (
    <div id='cgu'>
        <h2 ref={tittleCgu}>Conditions Générales d'Utilisation (CGU) de BH-DevService</h2>
        <br/>
        <br/>
        <h5>Date de Dernière Mise à Jour : 17/11/2023</h5>
        <br/>
        <h3>1. Introduction</h3>
        <br/><br/>
        <p>Bienvenue sur BH-DevService. Ces Conditions Générales d'Utilisation régissent votre accès et utilisation de notre site web et de nos services. En utilisant notre site, vous acceptez ces conditions dans leur intégralité.</p>
        <br/>
        <h3>2. Services Offerts</h3>
        <br/><br/>
        <p>BH-DevService propose deux services principaux :</p>
        <h5>Développement Web et Application :</h5>
        <p>Nous offrons des services de développement web et d'application sur mesure. La demande de devis et les discussions initiales ne nécessitent pas d'inscription.</p>
        <h5>Support Informatique via IA : </h5>
        <p> Un service de support informatique est disponible via une IA. L'utilisation de ce service requiert une inscription et l'achat de crédits via notre site.</p>
        <br/>
        <h3>3. Inscription et Compte Utilisateur</h3>
        <br/><br/>
        <p>Pour accéder à certains services, notamment le support informatique via IA, vous devez créer un compte. En vous inscrivant, vous vous engagez à fournir des informations exactes et à maintenir la sécurité de votre compte.</p>
        <br/>
        <h3>4. Achat de Crédits</h3>
        <br/><br/>
        <p>Les crédits nécessaires pour utiliser le service de support informatique peuvent être achetés sur notre site. Les transactions sont gérées par Stripe, et nous ne stockons aucune information bancaire.</p>
        <br/>
        <h3>5. Utilisation des Services</h3>
        <br/><br/>
        <p>Vous vous engagez à utiliser nos services de manière responsable et légale. Toute utilisation abusive ou non conforme peut entraîner la suspension ou la résiliation de votre accès aux services.</p>
        <br/>
        <h3>6. Propriété Intellectuelle</h3>
        <br/><br/>
        <p>Le contenu de BH-DevService, y compris les textes, graphiques, logos, et logiciels, est notre propriété exclusive et est protégé par les lois sur la propriété intellectuelle.</p>
        <br/>
        <h3>7. Confidentialité et Données Personnelles</h3>
        <br/><br/>
        <p>Vos données personnelles sont traitées conformément à notre Politique de Confidentialité. Nous prenons des mesures pour protéger vos informations contre l'accès non autorisé ou l'utilisation abusive.</p>
        <br/>
        <h3>8. Modifications des CGU</h3>
        <br/><br/>
        <p>Nous nous réservons le droit de modifier ces CGU à tout moment. Toute modification sera affichée sur cette page avec la date de la dernière mise à jour.</p>
        <br/>
        <h3>9. Contact</h3>
        <br/><br/>
        <p>Pour toute question ou préoccupation concernant ces CGU, veuillez nous contacter à <a href="mailto:hugo.brancatelli@bh-devservice.com">hugo.brancatelli@bh-devservice.com</a>.</p>
        <br/>
        <h3>10. Juridiction et Lois Applicables</h3>
        <br/><br/>
        <p>Les présentes Conditions Générales d'Utilisation sont régies par et interprétées conformément aux lois du pays où est enregistrée BH-DevService, sans égard aux principes de conflit de lois. Tout litige relatif à ces conditions sera soumis à la juridiction exclusive des tribunaux de ce pays.</p>        
    </div>
  )
}

export default Cgu