import React, { useState, useEffect} from "react";
import { UserService } from "../_services/UserService";
import useValidation from "../../hooks/useValidation";
import "../../styles/components/UserRegister.css";
import UserLogin from "./UserLogin";
import swal from "sweetalert";

function UserRegister(props) {
  //State formulaire envoyé et validé
  const [isRegisterSend, setIsRegisterSend] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  //State visionner mot de passe par utilisateur
  const [showPassword, setShowPassword] = useState(false);

  //State de formulaire
  const [formData, setFormData] = useState({
    name: "",
    first_name: "",
    email: "",
    newletter: "false",
    password: "",
    passwordConfirm: "",
  });
 
  // État pour les erreurs de validation non-Yup
  const [formErrors, setFormErrors] = useState({});

  // Utiliser le hook de validation
  const [validationErrors, validate] = useValidation();

  //Fonction voir mot de passe
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  // Gérer les changements dans les champs de formulaire
  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

// Gérer la soumission du formulaire
const handleSubmit = async (e) => {
  e.preventDefault();
  setIsButtonClicked(true);
  setFormErrors({}); // réinitialiser les erreurs de formulaire spécifiques à chaque soumission


  if (formData.password !== formData.passwordConfirm) {
    // Définir une erreur spécifique lorsque les mots de passe ne correspondent pas
    setFormErrors({ passwordConfirm: "Les mots de passe ne correspondent pas." });
    setIsButtonClicked(false); // Réafficher le bouton
    return;
  }

  // Valider les données du formulaire
  if (await validate(formData)) {
    // Si la validation réussit, envoyer les données
    UserService.postRegisterUser(formData)
      .then(({ data, status, error }) => {
        // Gestion de la réponse/erreur ici
        if (error) {
          // Verification status erreur
          if(error.status === 409){
            swal(error.data.message); 
            setIsButtonClicked(false);
          }};
        // ...
        if (status === 200) {
          setIsRegisterSend(true);
        };
      })
      .catch((error) => {
        swal("Une erreur inattendue est survenue. Veuillez réessayer.");
        setIsButtonClicked(false); // Réafficher le bouton si une erreur se produit
      });
  } else {
    setIsButtonClicked(false); // Réafficher le bouton si une erreur de validation se produit
  }
};
  const handleCloseClick = () => {
    // Ferme la modal
    props.onClose();
  };

  // Si l'enregistrement est réussi, ouvrerture de la modal de login après un délai
  useEffect(() => {
    if (isRegisterSend) {
      swal("Votre enregistrement a bien été pris en compte")
        .then(()=>{
          setIsLoginModalOpen(true);
        })
    }
  }, [isRegisterSend]);


  return  (
    <div className="modal">
      <button className="closeButton" onClick={handleCloseClick}>
        Fermer
      </button>
      {isLoginModalOpen?(
        
      <UserLogin isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

      ):(
      <div className="register-container">
        <form className="form-modal" onSubmit={handleSubmit}>
          <label className="label-modal">Nom :</label>
          <input
            className="input-modal"
            type="text"
            name="name"
            value={formData.nom}
            onChange={handleChange}
            placeholder="Nom"
            required
          />
          {validationErrors.name && <p style={{ color: "red" }}>{validationErrors.name}</p>}

          <label className="label-modal">Prénom :</label>

          <input
            className="input-modal"
            type="text"
            name="first_name"
            value={formData.nom}
            onChange={handleChange}
            placeholder="Prénom"
            required
          />
          {validationErrors.first_name && <p style={{ color: "red" }}>{validationErrors.first_name}</p>}

          <label className="label-modal">Email :</label>

          <input
            className="input-modal"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          {validationErrors.email && <p style={{ color: "red" }}>{validationErrors.email}</p>}

          <label className="label-checbox-modal">
            <input
              className="checbox-modal"
              name="newletter"
              type="checkbox"
              checked={formData.accepteConditions}
              onChange={handleChange}
              defaultValue={"false"}
            />

            Inscription à la NewLetter (restez informé des nouveaux services et
            prestations).
          </label>
          {validationErrors.newletter && <p style={{ color: "red" }}>{validationErrors.newletter}</p>}
          <br/>
          <p>Pour assurer une protection optimale, veuillez respecter les critères suivants :</p>
          <ul>
            <li>12 caractères minimum</li>
            <li>Au moins une lettre majuscule</li>
            <li>Au moins une lettre minuscule</li>
            <li>Au moins un chiffre</li>
            <li>Au moins un caractère spécial</li>
          </ul>
          
          <br/><label className="label-modal">Mot de passe :</label>
        <div className="sectionPassword">
          <input
            className="input-modal"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Mot de passe"
            required
          />
          <button className="buttonPasswordChecked" type="button" onClick={handleTogglePassword}>
             {showPassword ? '*' : '*'} 
          </button>
          </div>
          {validationErrors.password && <p style={{ color: "red" }}>{validationErrors.password}</p>}


          <label className="label-modal">Confirmation de Mot de passe :</label>
          <div className="sectionPassword">
          <input
            className="input-modal"
            type={showPassword ? 'text' : 'password'}
            name="passwordConfirm"
            value={formData.passwordConfirm}
            onChange={handleChange}
            placeholder="Confirmer votre mot de passe"
            required
          />
          <button className="buttonPasswordChecked" type="button" onClick={handleTogglePassword}>
            {showPassword ? '*' : '*'} 
          </button>
          </div>

          {formErrors.passwordConfirm && <p style={{ color: "red" }}>{formErrors.passwordConfirm}</p>}
          <br/>
      <p>Je vous remercions de votre coopération dans le renforcement de la sécurité de votre compte.</p>
     {isButtonClicked ? (<div>
      <br/><br/>
     </div>
      ):(
      <>
      <br/><br/>
       <button className="buttonFormulaire" type="submit" > 
               Envoyer
        </button>
      <br/><br/>  
      </>)}    
           </form>
      </div>)}
        <br/><br/>
    </div>
  );
}

export default UserRegister;
