import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import useWindowSize from '../hooks/UseWindowSize';
import '../styles/components/Menu.css';

function Menu({ isOpenMenuBurger, setIsOpenMenuBurger }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);  
  
  const windowSize = useWindowSize();
  const { width } = windowSize || {};

  useEffect(() => {
    setIsSmallScreen(width <= 767);
    setIsMediumScreen(width >=768);
  }, [width]);

 function handleClick(value){

      switch (value) {
          case "/":
              setIsOpenMenuBurger(false);
        break;
          case "/développement-web":
              setIsOpenMenuBurger(false);
        break;
          case "/Services-en-ligne":
              setIsOpenMenuBurger(false);
               break;
            case "/Horaire":
              setIsOpenMenuBurger(false);
            break;
          case "/Contacter-BH-DevService":
              setIsOpenMenuBurger(false);
        break;
    default:
      break;
      }

}

const NavigationLinks = () => (
  isMediumScreen ?
( 
    <div className="menu-container">
    <img src='images\CodeLineBackground.gif' className="menu-background-video" alt='ligne de code qui défile en de menu'/>
    <nav className='Bloc-menu-medium'>
        <img src='/globalNetword.png' className="app-logo-netword" alt="logo svg animé de la terre connecté qui tourne" />
        <NavLink to="/" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/")}>Accueil</NavLink>
        <NavLink to="/développement-web" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/développement-web")}>Développement Web</NavLink>
        <NavLink to="/Services-en-ligne" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Services-en-ligne")}>Services en ligne</NavLink>
        <NavLink to="/Horaire" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Horaire")}>Horaires</NavLink>
        <NavLink to="/Contacter-BH-DevService" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Contacter-BH-DevService")}>Contact</NavLink>
    </nav>
    </div>
)

  :
  ( 
    <div className="menu-container">
    <img src='images\CodeLineBackground.gif' className="menu-background-video" alt='ligne de code qui défile en de menu'/>
     <nav className='Block-Menu'>
        <img src='/globalNetword.png' className="app-logo-netword" alt="logo svg animé de la terre connecté qui tourne" />
        <NavLink to="/" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/")}>Accueil</NavLink>
        <NavLink to="/développement-web" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/développement-web")}>Développement Web</NavLink>
        <NavLink to="/Services-en-ligne" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Services-en-ligne")}>Services en ligne</NavLink>
        <NavLink to="/Horaire" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Horaire")}>Horaires</NavLink>
        <NavLink to="/Contacter-BH-DevService" className={({isActive}) => (isActive ? 'App-link-activ' : 'App-link')} onClick={()=>handleClick("/Contacter-BH-DevService")}>Contact</NavLink>
        </nav>
    </div>
      )
  );

  return (
    <>
      {isSmallScreen ? (
        isOpenMenuBurger && <NavigationLinks />
      ) : (
        <NavigationLinks />
      )}
{isSmallScreen && (
  <div className='bloc-button-menu'>
    <button
      className={isOpenMenuBurger ? 'buttonBurgerOpen' : 'buttonBurger'}
      onClick={() => setIsOpenMenuBurger(!isOpenMenuBurger)}
    >
    <p> {isOpenMenuBurger ? '⬅ ⬅ ⬅ F E R M E R ⬅ ⬅ ⬅' : '➡ ➡ ➡ M E N U ➡ ➡ ➡'}</p>
    </button>
  </div>
)}

    </>
  );
}

export default Menu;
