import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import'../../styles/components/CheckoutForm.css';
import { UserService } from "../_services/UserService";
import swal from "sweetalert";
import { useState } from "react";
import Loading from "../icone/Loading";

const CARD_ELEMENT_OPTIONS = {
    classes: {
      base: 'my-custom-class',  // Cette classe est appliquée à la base du composant, c'est-à-dire qu'elle sera toujours présente quelle que soit l'état du composant.
      
      complete: 'my-custom-complete-class',  // Cette classe est appliquée lorsque l'utilisateur a complètement rempli le champ de la carte (par exemple, lorsqu'il a entré un numéro de carte valide).
      
      empty: 'my-custom-empty-class',  // Cette classe est appliquée lorsque le champ de la carte est vide.
      
      focus: 'my-custom-focus-class',  // Cette classe est appliquée lorsque le champ de la carte est en focus (lorsque l'utilisateur clique dessus ou tabule dessus).
      
      invalid: 'my-custom-invalid-class',  // Cette classe est appliquée lorsque l'utilisateur a entré des données invalides, par exemple, un numéro de carte incorrect.
      
      webkitAutofill: 'my-custom-webkit-autofill-class'  // Cette classe est spécifique aux navigateurs basés sur WebKit (comme Chrome). Elle est appliquée lorsque le navigateur remplit automatiquement le champ grâce à la fonctionnalité d'auto-remplissage.
    
    },
  };
function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  
  const rechargeOptions = [
    { display: '5€', value: '500', reelValue: '20 crédits'},
    { display: '10€', value: '1000', reelValue: '50 crédits'},
    { display: '20€', value: '2000', reelValue: '120 crédits'},
    { display: '50€', value: '5000', reelValue: '300 crédits'}
  ];
  
  const textForLoading="Veuillez patienter, votre achat est en cours de traitement.";
  const [selectedAmount, setSelectedAmount] = useState({ display: '5€', value: '500', reelValue: '10 crédits'});
  const [isButtonSend, setIsButtonSend] = useState(false);
  const [isWaitingPaymentValidation, setIsWaitingPaymentValidation]=useState(false);
  const [isSuccessPayment, setIsSuccessPayment]=useState(false);
  const handleSubmit = async (event) => {
      event.preventDefault();
      setIsButtonSend(true);
      
      if (!stripe || !elements) {
          swal("Le service de paiement n'est pas disponible.");
          setIsButtonSend(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);


      const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

     setIsButtonSend(true)
     setIsWaitingPaymentValidation(true);

    if (error) {
      swal(error.message)
        .then(
          setIsWaitingPaymentValidation(false),
          setIsButtonSend(false)
        );
    } else {
      UserService.rechargeCreditWithStripe({
            paymentMethodId: paymentMethod.id,
            amount: selectedAmount.value
          })
            .then((data)=>{
              if(data.status===200){
                if(data.data.requires_action){
                  stripe.confirmCardPayment(data.data.client_secret)
                  .then(function(result) {
                    if (result.error) {
                        // Informer l'utilisateur que l'authentification a échoué
                        swal("L'authentification a échoué", result.error.message);
                        setIsButtonSend(false);
                        setIsWaitingPaymentValidation(false);
                    } else {
                        // Le paiement a été traité avec succès
                        if (result.paymentIntent.status === 'succeeded') {
                          UserService.rechargeCreditWithStripe({
                            paymentMethodId: paymentMethod.id,
                            amount: selectedAmount.value,
                            confirmation3DsecurePayment : "succeeded"
                              })
                              .then(
                                swal("Paiement réussi"),
                                setIsButtonSend(false),
                                setIsSuccessPayment(true)
                              )
                        }
                    }
                });
                }
                else{
                  swal(data.data.message)
                    .then(
                      setIsButtonSend(false),
                      setIsSuccessPayment(true),
                   )               
                }
              }else{
                if(data.error.status===500){
                  if(data.error.data.error.message ==="Your card was declined."){
                    swal("Votre carte a été refusé par votre banque.")
                    .then(setIsButtonSend(false),
                    setIsWaitingPaymentValidation(false))
                  }
                  else if(data.error.data.error.message ==="Your card has insufficient funds."){
                    swal("Paiement refusé par votre banque, fond insuffisant.")
                      .then(setIsButtonSend(false),
                      setIsWaitingPaymentValidation(false))
                  }else {

                    swal(data.error.statusText, "Une erreur interne est survenue, veuillez rééssayer ultérieurement.")
                        .then(setIsButtonSend(false),
                        setIsWaitingPaymentValidation(false))
                  }
                }
              }
            })
            .catch((error)=>{
                  swal("Un problème est survenu", error)
                    .then(setIsButtonSend(false),
                    setIsWaitingPaymentValidation(false)
                    )                
            })
    }
  };

  return isWaitingPaymentValidation?(
   <>
    {isSuccessPayment ?(
<div>
  <h3>Votre achat de crédit a été éffectué avec succés, merci de votre confiance.</h3>
</div>
    ):(
      <div>
      <Loading textProps={textForLoading}/>
    </div>
    )}
</>
    ):(
    <div>
    <div className="recharge-credit-bloc-choose">
      <h2>Obtenir des crédits</h2>
        <h3>Choisissez le montant de votre recharge:</h3>
         <div>  
   <div className="bloc-choose"> {rechargeOptions.map((option) => (
      
      <button 
        key={option.value}
        onClick={() => setSelectedAmount(option)}
        className={`${selectedAmount.value === option.value? 'recharge-button-selected' : 'recharge-button'}`}
        >
        {option.display}
      </button>
        ))}</div></div>
    <h4>Recharge selectionnée :</h4>
    {selectedAmount && (
      <div>
        Recharge sélectionnée : {selectedAmount.display} contre {selectedAmount.reelValue}
      </div>
    )}
  </div>
      <div className="bloc-payment">
        <form className="formPayment" onSubmit={handleSubmit}>
          <div className="cardElement">
              <CardElement  options={CARD_ELEMENT_OPTIONS} />
          </div>
  {isButtonSend ?(
<></>
  ):(
    <div className="bloc-button-payment">
    <button className="button-payment" type="submit" disabled={!stripe}>
        Payer
      </button>
  </div>
  )}

          
        </form>
      </div>
    </div>
  );
}

export default CheckoutForm;