import React from 'react';
import '../../styles/components/ButtonResetConsentUser.css';

function ResetConsentUser({ onConsentChangeChooce }) {

    const resetConsent =()=>{  
      onConsentChangeChooce();
    }
  return (
    <div className='button-reset'>
            <img  onClick={resetConsent} className='image-cookies-consent' src='/images/Cookies.png' alt='Cookies'/>
    </div>
  )
}

export default ResetConsentUser