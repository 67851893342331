import React, { useState, useEffect, useRef } from 'react';
import { UserService } from '../_services/UserService';
import Loading from '../icone/Loading';
import swal from 'sweetalert';
import ProfilPage from '../../pages/ProfilPage';
import ReactGA from 'react-ga4';
import '../../styles/components/ComputerHelpAI.css';

function ComputerHelphAI() {
  const [OpenProfil, setOpenProfil] = useState(false);

  const tittleHugoAi =useRef(null);
  useEffect(() => {
    tittleHugoAi.current.scrollIntoView({behavior:'smooth'});
  }, []);

  const textForLoading="Veuillez patienter un petit moment, une réponse bien préparée est toujours plus explicite. 😊";
  const [isNewQuestion, setIsNewQuestion]=useState(false);
  const [userInput, setUserInput] = useState('');
  const [operatingSystem, setOperatingSystem] = useState('windows');
  const [operatingSystemOptions] = useState([
    {display:'Windows', value:'windows'},
    {display:'Mac OS', value:'macos'},
    {display:'Linux', value:'linux'}  
  ]);
  const handleInputChange = (e) => {
    if (e.target.value.length <= 500) {
      setUserInput(e.target.value);
    }
  }
  const [gptResponse, setGptResponse] = useState('');

  const [isPrompt, setIsPrompt] =useState(false);
  const [isLoading, setIsLoading] =useState(false);

 
  const handleClickScroll = () => {

  const element = document.getElementById('response-preserve-newline');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
const handleSubmit = async () => {
  try {
      // Suivi de l'événement avec Google Analytics
      ReactGA.event('send_question_hugoIA',{
        category: 'Utilisateur',
        label: 'Service HugoIA'
    });
    //Passe isPrompt a false
    setIsLoading(true)
    setIsPrompt(true)
    if(operatingSystemOptions===null){
      swal("Veuillez selectionner le système d'exploitation.");
      setIsLoading(false);
      setIsPrompt(false);
      return;
    }
    if(userInput.length<1){
      swal("Veuillez saisir votre question.");
      setIsLoading(false);
      setIsPrompt(false);
      return;
    }

    const response = await UserService.postApiService({ userInput, operatingSystem });
  
    if(response.status === 200){
      setGptResponse(response.data.response);
      setIsPrompt(true);
      setIsLoading(false);
      handleClickScroll();
      setIsNewQuestion(true);
    }
  } catch (error) {
    if(error !=="Network Error"){
      if(error.response.status === 403){
          swal("Veuillez ajouter des crédits si vous souhaitez utiliser ce service.")
    }
    else if(error.response.status === 422){
      swal("Le choix du model ne correspond pas.")
    }
    else if(error.response.status){
      swal("Une dépence externe ne répond pas,veuillez rééssayer plus tard.")
    }  
    else{
      swal("Erreur lors de la communication avec le server, veuillez rééssayer plus tard.")
  }
}
    setIsPrompt(false);
    setIsLoading(false);
  }
  
};

const askQuestion =()=>{
  setGptResponse('');
  setUserInput('');
  setIsPrompt(false);
  setIsNewQuestion(false);
}
const handleClose = () => {
  setOpenProfil(false);
}
return (
  
  <div>
    <div id="support">
            <h2 ref={tittleHugoAi}>HugoIA</h2>

            <h3> à votre service</h3>
            <br/>
  {isLoading ?(<div>
                <Loading textProps={textForLoading}/>
              </div>
              ):(
              <div>
              {isPrompt? (<div id='response-preserve-newlines'>{gptResponse}</div>) : ( <div className='bloc-echange-hugoai'>
                <h4>Explorez notre service inédit dédié à la résolution rapide de problèmes informatiques. Que vous soyez confronté à des questions complexes de programmation, à des défis techniques ou à des problèmes logiciels, notre outil offre des solutions efficaces. Obtenez des réponses claires et concises à vos interrogations informatiques, accélérant ainsi votre progression dans le monde numérique. Simplifiez votre expérience informatique avec notre service, conçu pour vous offrir des solutions pratiques et une expertise à portée de main.</h4>
                <h5>Renseigner le questionnaire ci-dessous, et je vous répondrez d'ici quelques instants</h5>
                <br/>
                <label>Quel système d'exploitation utilisez-vous ? Choisissez parmi la liste déroulante :</label>
                <select value={operatingSystem} onChange={e => setOperatingSystem(e.target.value)}>
                  {operatingSystemOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.display}</option>
                  ))}
                </select>
                <br/><br/>
                <label>Décrivez le problème auquel vous êtes confronté, ou dites moi simplement en quoi puis-je vous aider :</label>
                <textarea className='textarea' value={userInput} onChange={handleInputChange} />
                <span id="charCount" className='text-compte'>Caractères: {userInput.length}/500</span>

                <br/>
          
                <div>
                  <button className='buttonFormulaire' onClick={handleSubmit}>Envoyer</button>                 
                </div>
              
                <br/><br/>
                <div></div>
                <br/><br/>
              </div>)}
              
            </div>)}  
            {isNewQuestion?(
                <div>
                  <button className='buttonFormulaire' onClick={askQuestion}>Nouvelle question</button>                

                </div>
              ):(<div></div>)}
    
    </div>
    {OpenProfil && <ProfilPage onClose={handleClose} initialSelectedSection="CheckoutForm" /> }

    <br/><br/>
  </div>
  )
}

export default ComputerHelphAI