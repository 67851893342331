import React from 'react';
import '../../styles/components/Loading.css'

function Loading(props) {
  return (
    <div className='bloc-loading'>
        <p className='text-loading'>{props.textProps}</p>
        <img src='/images/iconeLoading.png' className="app-logo-loading" alt="logo svg animé de chargement" />
    </div>
  )
}

export default Loading;