import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexte/Auth';
import '../styles/components/Header.css';
import LogoutButton from './buttons/LogoutButton';
import LoginButton from './buttons/LoginButton';
import RegisterButton from './buttons/RegisterButton';
import ProfilUserButton from './buttons/ProfilUserButton';


function Header({ setIsOpenMenuBurger }) {
  const { isAuthenticated} = useAuth();

  const [welcomeTitle, setWelcomeTitle] = useState("");
  const [title, setTitle] = useState("");
  const [iW, setIW] = useState(0);
  const [iT, setIT] = useState(0);
  
  const newWelcomeTitle = "Bienvenue chez";
  const newTitle = "BH - DevService";
  
  
  useEffect(() => {
    if (iW < newWelcomeTitle.length) {
      setWelcomeTitle(prevValue => prevValue + newWelcomeTitle.charAt(iW));
      const timer = setTimeout(() => {
        setIW(prevIW => prevIW + 1);
      }, 100);
      // Clean up timer
      return () => clearTimeout(timer);
    } else if (iT < newTitle.length) {
      setWelcomeTitle("");
      setTitle(prevValue => prevValue + newTitle.charAt(iT));
      const timer = setTimeout(() => {
        setIT(prevIT => prevIT + 1);
      }, 200);
      // Clean up timer
      return () => clearTimeout(timer);
    }
  }, [iW, iT]);

  return (
    <header className="bloc-header">      
        <div className='bloc-logo'>
          <img src='\images\BH-DevService.png' className="app-logo-entreprise" alt="logo de l'entreprise BH-DevService" />
        </div>
        <div className='bloc-login-register'>
            {isAuthenticated ? (
              <div>
                <ProfilUserButton />
                  <LogoutButton />
              </div>
            ) : (
                <div>
                  <LoginButton />
                  <RegisterButton />
                </div>
            )}
        </div>
        <div className='bloc-title'>
          <div id="welcome-title">{welcomeTitle}</div>
          <div id="title">{title}</div>
        </div>
      </header>
      );
}

export default Header;
