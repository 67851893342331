import React, {useState} from 'react';
import Profil from '../components/profil/Profil';
import History from '../components/profil/History';
import CheckoutForm from '../components/checkoutForm/CheckoutForm';
import { useAuth } from '../contexte/Auth';
import UserLogin from '../components/formLoginContact/UserLogin';
import '../styles/pages/ProfilPage.css';

function ProfilPage(props) {

    const [selectedSection, setSelectedSection] = useState(props.initialSelectedSection || 'Profil');
    const {isAuthenticated}=useAuth();

    

    //Function Ferme la modal
    const handleCloseClick = () => {
        if (props.onClose) {
            props.onClose();
        }    };

    const handleSwitchClick = (target) => () => {
        setSelectedSection(target);

    };

    const renderSection = () => {
        switch (selectedSection) {
            case 'Profil':
                return <Profil />;
            case 'History':
                return <History />;
            case 'CheckoutForm':
                return <CheckoutForm />;
            default:
                return <div></div>;
        }
    };

  return isAuthenticated?(
    <div className="modal">
        <button className="closeButton" onClick={handleCloseClick}>
            Fermer
        </button>
        <div className="container">
            <div className='button-Modal-switch'>
        <button 
        className={`switchModalContent ${selectedSection === 'Profil' ? 'switchModalContentSelected' : ''}`}
        onClick={handleSwitchClick("Profil")}
        >
        Profil
        </button>
        <button 
        className={`switchModalContent ${selectedSection === 'History' ? 'switchModalContentSelected' : ''}`}
        onClick={handleSwitchClick("History")}
        >
        Historique
        </button>
        <button 
        className={`switchModalContent ${selectedSection === 'CheckoutForm' ? 'switchModalContentSelected' : ''}`}
        onClick={handleSwitchClick("CheckoutForm")}
        >
        Ajouter des Crédits
        </button>

            </div>
            <div>
            {renderSection()}
            </div>
        </div>
    </div>
  ):(<div>
    <UserLogin onClose={handleCloseClick}/></div>)
}

export default ProfilPage