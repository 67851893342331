import React, { useState, useRef, useEffect } from 'react';

function AudioPlay({audioSrc , voiceSelected}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
    
        const handleCanPlayThrough = () => {
          if (isPlaying) {
            audio.play();
          }
        };
    
        const handleEnded = () => {
          // La lecture est terminée, mettre à jour l'état
          setIsPlaying(false);
        };
    
        audio.addEventListener('canplaythrough', handleCanPlayThrough);
        audio.addEventListener('ended', handleEnded);
    
        return () => {
          audio.removeEventListener('canplaythrough', handleCanPlayThrough);
          audio.removeEventListener('ended', handleEnded);
        };
      }, [audioRef, isPlaying]);
    
      const toggleAudio = () => {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      };
  
  return (
    <div>
      <audio ref={audioRef} src={audioSrc} type="audio/wav" />
      <button className='button-play-audio' onClick={toggleAudio}>
        {isPlaying ? <img className='image-play-audio' src='/images/stopButton.png' alt='Play' /> : <img className='image-play-audio' src='/images/playButton.png' alt='Play' />}
      </button>
    </div>
  )
}

export default AudioPlay;