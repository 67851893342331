import React from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexte/Auth';
import '../../styles/components/Buttons.css';

function LogoutButton() {
    const { logout } = useAuth();

  return (
    <div>
        <Link to="/" className='App-link-footer'>
           <button className='buttons-login' onClick={logout}>Se déconnecter</button>
        </Link>
    </div>
  )
}

export default LogoutButton