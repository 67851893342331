import React,{useRef, useEffect} from 'react';
import ProfilUserButton from '../components/buttons/ProfilUserButton';
import '../styles/pages/Home.css';

function Home() {
    const tittleHome=useRef(null);

    useEffect(() => {
        tittleHome.current.scrollIntoView({behavior:'smooth'});
    }, []);

  return (
    <div id="presentation">
        <div className="introPage">
            <h2 ref={tittleHome}>BH-DevService ?</h2>
            <br/><br/>
            <p className="texteBlocIntro">Bonjour et bienvenue chez <abbr id="nomEntreprise"><strong>BH-DevService</strong> </abbr> !<br/>
                <br/> Je m' appelle <a className="ancreExterne" href="https://www.linkedin.com/in/hugo-brancatelli-302152243/" target="_blank" rel='noreferrer'><strong>Hugo Brancatelli</strong>,</a> passionné par la technologie informatique, issu d'une formation Développeur Full Stack JavaScript, j'ai créé cette entreprise afin de pouvoir apporter mes services à tous, particulier comme professionel.
                <br/> Si vous cherchez des services de développement web de qualité personnalisée ou des outils qui simplifient et améliorent votre vie numérique, vous frappez à la bonne porte chez <strong>BH-DevService</strong>. J'ai à cœur de créer des solutions web exceptionnelles, conçues sur mesure pour répondre à vos besoins. Ensemble, nous pouvons rendre votre expérience en ligne plus fluide et plus enrichissante. Faites-moi confiance pour transformer vos idées en réalité numérique.
            </p><br/>
            <p id="serviceListePresentation">Retrouver ci-dessous un résumé de mes différents services</p>
            <br/>
        </div>
        <br/>                   
        <div className="mainBlocPage">
            <h3>Services de Développement Web </h3>
            <h4>Conception d'applications web responsive </h4>
            <br/>
            <p>Que vous ayez besoin d'une application web pour votre entreprise ou un projet personnel, je suis là pour vous aider à concevoir une solution sur mesure, optimisée pour tous les appareils, qu'il s'agisse de smartphones, de tablettes ou d'ordinateurs de bureau.</p>
            <br/>
        </div>
        <div className="mainBlocPage">
        <h3>Services en ligne</h3>
        <h4>Outils pour faciliter la vie numerique, utilisable avec un syteme de crédit.</h4>
        <br/>
    <p>Explorez l'avenir de la technologie avec mes services en constante évolution, conçus sur mesure pour répondre à tous vos besoins. Plongez dans une gamme variée d'outils en ligne qui simplifieront votre vie numérique avec aisance et élégance.</p>
    <br/>
    <br/>
    <div id="hugoia-service">
        <h6>Voici le premier service proposé :</h6>
        <h5>HugoIA</h5>
        <br/>
        <p>Avec HugoIA, accédez à un support informatique innovant. Cette intelligence artificielle spécialisée vous accompagne sur tous les sujets informatiques, disponible à tout moment pour vous assister, éduquer et résoudre vos problèmes techniques.</p>
        <br/>
        <br/>
        <div id="credits-purchase">
            <h3>Obtenez des Crédits Facilement pour utiliser mes services</h3>
        <p>Achetez des crédits directement via notre application et commencez à simplifier votre expérience informatique dès aujourd'hui!</p>
            <p>Les crédits sont la monnaie de notre plateforme. Obtenez-les facilement via l'application dans la section profil </p>
            <ProfilUserButton isCustomStyle={true} initialSelectedSection="CheckoutForm"/>
            <p> et utilisez-les pour obtenir des réponses et des solutions de notre IA dédiée à l'informatique.</p>            
        </div>
    </div>
    </div>
        <div>
            <p>Mon objectif est de rendre la technologie informatique accessible à tous et de fournir des solutions web de qualité. Que vous ayez un projet en tête ou que vous ayez simplement besoin d'aide pour tirer le meilleur parti de vos outils informatiques, n'hésitez pas à me contacter. Je suis là pour vous accompagner dans toutes vos démarches informatiques !</p>
            <br/>
        </div>
        <br/>                   
        <br/> 
    </div>
      )
}

export default Home