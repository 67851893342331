import React from 'react';
import '../styles/components/404.css';

function Page404() {
  return (
    <div id='page404'>
      <h2>404 : Zone de Bug Inattendue !</h2>
          <img src='\images\Image404.png' className="img-404" alt="Page non trouvée 404 - BH-DevService" />
      <h3>Veuillez retourner sur le site depuis le menu de gauche !</h3>
    </div>
  )
}

export default Page404