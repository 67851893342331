import { useState } from 'react';
import * as yup from 'yup';

  
function useValidationPassword(schema) {
    const [errors, setErrors] = useState({});  
 
    //Shema :
      // Définir le schéma de validation avec yup
     const validationSchema = yup.object().shape({
      password: yup.string()
          .required('Le mot de passe est requis')
          .min(12, 'Le mot de passe doit contenir au moins 12 caractères')
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*['/_\-`~^.[\]$()*+?|{}@\\])[a-zA-Z0-9\S]{12,}$/,
          'Le mot de passe doit contenir 12 caractères minimun, au moins une majuscule, une minuscule, un chiffre et un caractère spécial'),
 
    });
  
    const validate = async (data) => {
      setErrors({}); // réinitialiser les erreurs précédentes
  
      try {
        await validationSchema.validate(data, { abortEarly: false });
        return true; // la validation a réussi
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const newErrors = {};
          err.inner.forEach((error) => {
            newErrors[error.path] = error.message;
          });
          setErrors(newErrors); // définir les messages d'erreur de validation
        }
        return false; // la validation a échoué
      }
    };
  
    return [errors, validate];
  }
  
export default useValidationPassword;