import React,{useState} from 'react';
import '../../styles/components/ContactForm.css';
import { UserService } from '../_services/UserService';
import Captcha from '../_services/Captcha';
import swal from 'sweetalert';
import ReactGA from 'react-ga4';

function ContactForm({ onValidationChange, onPreferenceChange }) {
    const [captchaValue, setCaptchaValue] = useState("blablalvcllz");
    
    const handleCaptcha = (value) => {
      // Ici, vous pouvez gérer la valeur du captcha, par exemple l'envoyer à votre serveur
      setCaptchaValue(value);
      setFormData({ ...formData, captchaToken: value });
    };
  
    const [formData, setFormData] = useState({
        nameOrSociety: '',
        email: '',
        phoneNumber: '',
        typeOfProject:'',
        goalsAndExpectations:'',
        sitesAndComments:'',
        completionTime:'',
        contactPreference:'',
        budgetForProject:'',
        captchaToken:''
    });
      // Initialiser l'état pour stocker le comptage des caractères pour chaque textarea
      const [comptes, setComptes] = useState({
        typeOfProject: 0,
        goalsAndExpectations: 0,
        sitesAndComments: 0,
        budgetForProject:0        
      });
    

    const handleChange=(e)=>{
        const {name , value} =e.target;
        setFormData(prevState =>({
            ...prevState,
            [name]:value
        }));

    // Si l'événement provient d'un textarea, mettez à jour le comptage des caractères.
    if (['typeOfProject', 'goalsAndExpectations', 'sitesAndComments', 'budgetForProject'].includes(name)) {
        setComptes(prevComptes => ({
            ...prevComptes,
            [name]: value.length,
        }));
        }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        if(captchaValue!==null){
            UserService.postFormContact(formData)
                .then(res=>{console.log(res)
                    if(res.status ===200){
                        onValidationChange(true); 
                        onPreferenceChange(formData.contactPreference);
                        // Suivi de l'événement avec Google Analytics
                        ReactGA.event('form_success',{
                            category: 'Utilisateur',
                            label: 'Page de demande de contact'
                        });
                    }
                    else if(res.error.status===403){
                       swal("le token Hcaptcha contient une erreur veuillez rééssayer.");
                        // Suivi de l'événement avec Google Analytics
                        ReactGA.event('Hcaptcha_echec',{
                            category: 'Utilisateur',
                            label: 'Page de demande de contact'
                        });
                    }else if(res.error.status===400){
                        swal("le formulaire contient des érreurs, veuillez vérifier et recommencer.");
                        // Suivi de l'événement avec Google Analytics
                        ReactGA.event('form_data_error',{
                            category: 'Utilisateur',
                            label: 'Page de demande de contact'
                        });
                    }
                })
                .catch(error=>{
                    swal("Une erreur s'est produite", error);         
                });
        }else{
            swal("Veuillez résoudre le hCaptcha pour valider le formulaire");
        }
    }
    
  return (
    <div className='bloc-Form'>
        <form onSubmit={handleSubmit}>
            <div className='bloc-label-input'>
                <label className="label">Nom complet ou raison sociale :</label>
                <input className='input' type="text" name="nameOrSociety" value={formData.nameOrSociety} onChange={handleChange}  placeholder="Entrez votre nom ou celui de votre entreprise)"required/>

                <label className="label">Adresse Email :</label>
                <input className='input' type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Entrez votre adresse email" required/>

                <label className="label">Numéro de téléphone :</label>
                <input className='input' type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Entrez votre numéro de téléphone"required/>
            </div>
            <div className='bloc-label-texterea'>
                <label className="label">Décrivez le projet que vous envisagez (site web, application web/mobile, API, etc.) :</label>
                <textarea name="typeOfProject" className="textarea" placeholder="Décrivez ici votre projet..." value={formData.typeOfProject} onChange={handleChange} maxLength="500"  required></textarea>
                <p className='text-compte'>Caractères: {comptes.typeOfProject}/500</p>

                <label className="label">Quels sont les objectifs principaux et vos attentes pour ce projet ?</label>
                <textarea name="goalsAndExpectations" className="textarea" placeholder="Expliquez vos objectifs et attentes..." value={formData.goalsAndExpectations} onChange={handleChange} maxLength="500"  required></textarea>
                <p className='text-compte'>Caractères: {comptes.goalsAndExpectations}/500</p>

                <label className="label">Existe-t-il des références ou des spécifications particulières pour votre projet ?</label>
                <textarea name="sitesAndComments" className="textarea" placeholder="Fournissez des références ou tout autre commentaire pertinent..." value={formData.sitesAndComments} onChange={handleChange} maxLength="500"  required></textarea>                     
                <p className='text-compte'>Caractères: {comptes.sitesAndComments}/500</p>

                <label className="label">Pourriez-vous m' indiquer votre budget approximatif pour ce projet ?</label>
                <div>
                    <input type="number" name="budgetForProject" className="input-budget" placeholder="Budget approximatif..." value={formData.budgetForProject} onChange={handleChange} maxLength="20"  required></input> €                     
                </div>
                <p className='text-compte'>Caractères: {comptes.budgetForProject}/20</p>                
            </div>
            <div className='bloc-label-choiceFromSelection'>
                <label className="label">Quel est votre délai souhaité pour la réalisation du projet ?</label>
                <select className='bloc-selectValue' name="completionTime" value={formData.completionTime} onChange={handleChange} required>
                    <option value="" disabled hidden className='optionValue'>Veuillez choisir un délai</option>
                    <option value="rapidement" className='optionValue'>Le plus tôt possible</option>
                    <option value="maximum un mois" className='optionValue'>Dans un mois au maximum</option>
                    <option value="moins de trois mois" className='optionValue'>Dans moins de trois mois</option>
                    <option value="moins de six mois" className='optionValue'>Dans moins de six mois</option>
                    <option value="pas de délais" className='optionValue'>Pas de délai spécifique</option>
                </select>

                <label className="label">Préférence pour le moyen de communication :</label>
                <select className='bloc-selectValue' name="contactPreference" value={formData.contactPreference} onChange={handleChange} required>
                    <option value="" disabled hidden className='optionValue'>Sélectionnez votre moyen de communication préféré</option>
                    <option value="Email" className='optionValue'>Email</option>
                    <option value="WhatsApp" className='optionValue'>WhatsApp</option>
                    <option value="Téléphone" className='optionValue'>Appel téléphonique</option>
                </select>
                <br/><br/>
                <Captcha onChange={handleCaptcha} />

            </div>
            <button className='buttonFormulaire' type="submit">Envoyer</button>
        </form>
        <br/><br/>
    </div>    
  )
}

export default ContactForm