import React, { useState} from "react";
import { UserService } from "../_services/UserService";
import useValidationPassword from "../../hooks/useValidationPassword";
import Loading from '../icone/Loading';
import swal from "sweetalert";

function UpdatePassword() {

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isUpdatePassword, setIsUpdatePassword] =useState(false);
    const [isLoading, setIsLoading]=useState(false);
    //State visionner mot de passe par utilisateur
    const [showPasswords, setShowPasswords] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });
    //State de formulaire
    const [formData, setFormData] = useState({
        passwordOld:"",
        password: "",
        passwordConfirm: "",
    });
    //Fonction voir mot de passe
    const togglePasswordVisibility = (fieldName) => {
        setShowPasswords(prevState => ({
            ...prevState,
            [fieldName]: !prevState[fieldName]
        }));
    };

    // État pour les erreurs de validation non-Yup
    const [formErrors, setFormErrors] = useState({});

    // Utiliser le hook de validation
    const [validationErrors, validate] = useValidationPassword();

    // Gérer les changements dans les champs de formulaire
    const handleChangePassword = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    };

    // Gérer la soumission du formulaire
const handleSubmitUpdatePassword = async (e) => {
    e.preventDefault();
    setIsButtonClicked(false);
    setIsLoading(true);
    setFormErrors({}); // réinitialiser les erreurs de formulaire spécifiques à chaque soumission
    
  
    if (formData.password !== formData.passwordConfirm) {
      // Définir une erreur spécifique lorsque les mots de passe ne correspondent pas
      setFormErrors({ passwordConfirm: "Les mots de passe ne correspondent pas." });
      setIsButtonClicked(false); // Réafficher le bouton
      return;
    }
  
    // Valider les données du formulaire
    if (await validate(formData)) {

      // Si la validation avec validator réussit, envoyer les données
     UserService.updatePassword(formData)
      .then(({ data, status, error }) => {
          // Gestion de la réponse/erreur ici
          if (error) {            
              swal(`${error.data.message}`); 
              setIsButtonClicked(false);
              setIsLoading(false);
          }
          if (status === 200) {
            setIsLoading(false);
            setIsUpdatePassword(true);
          };
        })
        .catch((error) => {
            swal(`Une erreur inattendue est survenue. Veuillez réessayer.(${error})`);
            setIsButtonClicked(false); // Réafficher le bouton si une erreur se produit
        });
    } else {
      setIsButtonClicked(false); // Réafficher le bouton si une erreur de validation se produit
    }
  };
  


  return (
    <div>
        <h2>Modification de votre mot de passe :</h2>
        <br/>
        <br/>
        {isUpdatePassword ?(
        <div>
            <h3>Votre changement de mot de passe a bien été pris en compte.</h3>
        </div>
        ):(
        <div>
            {isLoading ?(
            <div>
                <Loading />
            </div>
            ):(
<div>
            <form className="form-modal" onSubmit={handleSubmitUpdatePassword}>
            <label className="label-modal">Saisissez votre Mot de passe actuel:</label>
            <div className="sectionPassword">
            <input
                className="input-modal"
                type={showPasswords.oldPassword ? 'text' : 'password'}
                name="passwordOld"
                value={formData.passwordOld}
                onChange={handleChangePassword}
                placeholder="Mot de passe actuel"
                required
            />
            <button className="buttonPasswordChecked" type="button" onClick={() => togglePasswordVisibility('oldPassword')}>
                {showPasswords.oldPassword ? '*' : '*'} 
            </button>
            </div>
            {validationErrors.passwordOld && <p style={{ color: "red" }}>{validationErrors.passwordOld}</p>}  
            <label className="label-modal">Saisissez votre nouveau Mot de passe :</label>
            <div className="sectionPassword">
            <input
                className="input-modal"
                type={showPasswords.newPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleChangePassword}
                placeholder="Nouveau Mot de passe"
                required
            />
            <button className="buttonPasswordChecked" type="button" onClick={() => togglePasswordVisibility('newPassword')}>
                {showPasswords.newPassword ? '*' : '*'} 
            </button>
            </div>
            {validationErrors.password && <p style={{ color: "red" }}>{validationErrors.password}</p>}


            <label className="label-modal">Confirmation du nouveau Mot de passe :</label>
            <div className="sectionPassword">
            <input
                className="input-modal"
                type={showPasswords.confirmPassword ? 'text' : 'password'}
                name="passwordConfirm"
                value={formData.passwordConfirm}
                onChange={handleChangePassword}
                placeholder="Confirmation mot de passe"
                required
            />
            <button className="buttonPasswordChecked" type="button" onClick={() => togglePasswordVisibility('confirmPassword')}>
                {showPasswords.confirmPassword ? '*' : '*'} 
            </button>
            </div>
            {formErrors.passwordConfirm && <p style={{ color: "red" }}>{formErrors.passwordConfirm}</p>}
            {isButtonClicked ? (
            <div>
                <br/>envoi en cours<br/>
            </div>
            ):(
            <>
                <br/><br/>
                <button className="buttonFormulaire" type="submit" disabled={isButtonClicked} > 
                        Envoyer
                    </button>
                <br/><br/>
            </>
            )} 
            </form>

            </div>)}   
            
        </div> 
        )}
         
    </div>
  )
}

export default UpdatePassword;