import React,{useRef, useEffect} from 'react';
import'../styles/pages/OnlineService.css';
import { useNavigate } from 'react-router-dom';

function OnlineServicesPage() {
  const navigate = useNavigate();
  const tittleOnlineService =useRef(null);

  useEffect(() => {
    tittleOnlineService.current.scrollIntoView({behavior:'smooth'});
  }, [])
  

  function handleClick(value){
    switch (value) {
      case  "/Aide-informatique":
        navigate('/Aide-informatique')
        break;
        case  "/Obtenir-un-slogan":
          navigate('/Obtenir-un-slogan')
          break;
        case  "/Transformer-un-texte-en-audio":
          navigate('/Transformer-un-texte-en-audio')
          break;
      default:
        break;
    }
  }

  return (
    <div className='global-page-online-services'>
      <h2 ref={tittleOnlineService}>Services en ligne </h2>
        <br/> 
            <p className="texteBlocIntro">En tant que passionné d'informatique depuis mon plus jeune âge et utilisant un ordinateur quotidiennement, je souhaite mettre mes connaissances à votre disposition, en particulier pour les débutants en informatique. Je comprends que l'évolution rapide de la technologie peut être déconcertante pour certaines personnes. Aujourd'hui, l'informatique est omniprésente dans nos vies, et le numérique joue un rôle prépondérant.</p>
            <br/><br/>
            <p>C'est pourquoi, j'ai décidé de mettre à votre disposition différents services pour aider.</p>
      <h3>Service(s) en ligne que vous pourrez utiliser avec vos crédits, d'autres services seront prochainement ajoutés.</h3>
      <br/>
      <br/>
      <div className='bloc-service-global'>
        <h4>HugoIA est là pour vous aider !</h4>
        <br/>
        <div className='bloc-service'>
          <div className='bloc-service-description'>
              <p className='text-description'>HugoIA, votre assistant virtuel expert en informatique! Il est là pour résoudre tous vos problèmes et répondre à toutes vos questions dans le domaine de l'informatique. Simplifiez votre vie numérique avec HugoIA!</p>
          </div>
       <div className='bloc-service-imageText'>
        <img src='\images\HugoIA.jpg' className="logo-ComputerHelp" alt="logo d'un cliquable pour choisir un service de BH-DevService" onClick={()=>handleClick('/Aide-informatique')}/>
        <p className='textImage'>Cliquez sur l'image pour ouvrir le service</p>
        </div>
        </div>
      </div>
      <div className='bloc-service-global'>
        <h4>TexteImpactIA !</h4>
        <br/>
        <div className='bloc-service'>
          <div className='bloc-service-description'>
              <p className='text-description'>TexteImpactIA, votre partenaire pour des phrases d'accroche uniques et percutantes, parfaitement adaptées à votre CV ou à l'identité de votre entreprise. Nous transformons vos points forts en messages accrocheurs, garantissant une première impression inoubliable et un impact considérable.</p>
          </div>
       <div className='bloc-service-imageText'>
        <img src='\images\textImpactIA.jpg' className="logo-ComputerHelp" alt="logo d'un cliquable pour choisir un service de BH-DevService" onClick={()=>handleClick('/Obtenir-un-slogan')}/>
        <p className='textImage'>Cliquez sur l'image pour ouvrir le service</p>
        </div>
        </div>
      </div>
      <div className='bloc-service-global'>
        <h4>TextToSpeech !</h4>
        <br/>
        <div className='bloc-service'>
          <div className='bloc-service-description'>
              <p className='text-description'>TextToSpeech, un outil de pointe qui donne vie à vos mots en les transformant en une expérience audio immersive. Que vous ayez besoin de convertir des articles, des documents professionnels ou même des messages personnels en fichiers audio, TextToSpeech offre une solution conviviale et performante.</p>
          </div>
       <div className='bloc-service-imageText'>
        <img src='\images\textToSpeechImage.jpg' className="logo-ComputerHelp" alt="logo d'un cliquable pour choisir un service de BH-DevService" onClick={()=>handleClick('/Transformer-un-texte-en-audio')}/>
        <p className='textImage'>Cliquez sur l'image pour ouvrir le service</p>
        </div>
        </div>
      </div>      
      <br/>
      <br/>

    </div>
    
  )
}

export default OnlineServicesPage