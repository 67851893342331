import React,{useEffect, useRef} from 'react';
import '../styles/pages/PrivacyPolicies.css';

function PrivacyPolicies() {
  const tittlePrivacyPolicies=useRef(null);

  useEffect(() => {
      tittlePrivacyPolicies.current.scrollIntoView({behavior:'smooth'});
  }, []);

  return (
    <div id='privacyPolicies'>
      <h2 ref={tittlePrivacyPolicies}>Politique de confidentialité</h2>
            <br/><br/>
            <h5> Informations recueillies et Utilisation des informations</h5>
            <p>J'attache une grande importance à la protection de votre vie privée. Lorsque vous utilisez mes services pour envoyer des e-mails via EmailJS, je ne collecte ni ne stocke aucune donnée personnelle. Je n’utilise aucun cookie dans le cadre de mes services.</p><br/>
            <p>Toutes les informations que vous saisissez dans mon formulaire de contact sont utilisées uniquement pour l'envoi de l'e-mail et ne sont pas stockées sur un serveur. Je ne partage pas vos données avec des tiers.</p><br/>
            <h5>Sécurité</h5>
            <p>Je prends des mesures de sécurité appropriées pour protéger vos données contre toute utilisation non autorisée ou tout accès non autorisé. Cependant, veuillez noter que la sécurité des données sur Internet ne peut être garantie à 100 %.</p><br/>
            <h5>Liens vers des sites tiers</h5>    
            <p>Mon application peut contenir des liens vers des sites Web tiers. Je ne suis pas responsable des pratiques de confidentialité ou du contenu de ces sites. Je vous encourage à consulter les politiques de confidentialité de ces sites avant de les utiliser.</p><br/>
            <h5>Modifications de la politique de confidentialité</h5>
            <p>Je me reserve le droit de mettre à jour cette politique de confidentialité à tout moment. Toute modification sera affichée sur cette page avec la date de la dernière mise à jour. Je vous encourage vivement à consulter régulièrement cette politique pour rester informé de toutes modifications</p><br/>
            <h5>Propriété intellectuelle et droits d'auteur</h5>
            <p>Tout le contenu présent sur BH-DevService, y comprenant, mais sans s'y limiter, le texte, les graphiques, les logos, les images, les icônes, les clips audio, les vidéos, les logiciels et les autres éléments , est la propriété exclusive de BH-DevService et est protégé par les lois sur les droits d'auteur et autres lois sur la propriété intellectuelle applicables.</p><br/>
            <p>Vous n'êtes pas autorisé à copier, reproduire, distribuer, publier, afficher, modifier ou transmettre de quelque manière que ce soit le contenu de notre application sans notre consentement écrit préalable. Toute utilisation non autorisée du contenu peut constituer une violation de nos droits de propriété intellectuelle et peut être soumise à des poursuites judiciaires.</p><br/>
            <h5>Marques déposées</h5>
            <p>Toutes les marques déposées, marques de service, noms commerciaux et logos utilisés sur BH-DevService sont la propriété exclusive de BH-DevService. Ils ne peuvent être utilisés sans notre autorisation écrite préalable.</p><br/>
            <h5>Utilisation de Google Analytics</h5>
            <p>Nous utilisons Google Analytics, un service d'analyse web fourni par Google Inc. (« Google »), pour collecter des informations de la manière dont les utilisateurs interagissent avec notre site web. Google Analytics utilise des cookies pour collecter des données telles que l'adresse IP de votre appareil, le type de navigateur, les pages que vous visitez, la durée de votre visite et d'autres informations similaires.</p><br/>
            <p>Les informations fournies par Google Analytics sont utilisées pour analyser les tendances du trafic, suivre les performances de notre site web et améliorer l'expérience de l'utilisateur. Ces données ne sont pas associées aux informations personnelles vous concernant, et elles sont traitées de manière anonyme.</p><br/>
            <p>ous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés de votre navigateur ou en installant le module complémentaire de navigateur Google Analytics Opt-out disponible sur le site web de Google.</p><br/>
            <p>Pour en savoir plus sur la manière dont Google Analytics collecte et traite les données, veuillez consulter la politique de confidentialité de Google Analytics.</p><br/>
            <h5>Contacter</h5>
            <p>Si vous avez des questions ou des préoccupations concernant ma politique de confidentialité ou la manière dont j' utilise EmailJS, veuillez me contacter à <a href="mailto:bh.devservice@gmail.com">bh.devservice@gmail.com</a> pour obtenir des informations supplémentaires.</p><br/>           
            <br/><br/>
    </div>
  )
}

export default PrivacyPolicies