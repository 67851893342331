import React from 'react';
import ObtainCatchPhrase from '../components/serviceOnline/ObtainCatchPhrase';

function ObtainCatchPhrasePage() {
  return (
    <div>
      <ObtainCatchPhrase />
    </div>
  )
}

export default ObtainCatchPhrasePage;