import React,{useEffect, useRef, useState} from 'react';
import { UserService } from '../_services/UserService';
import Loading from '../icone/Loading';
import swal from 'sweetalert';
import ProfilPage from '../../pages/ProfilPage';
import '../../styles/components/ObtainCatchPhrase.css';
import ReactGA from 'react-ga4';

function ObtainCatchPhrase() {
    const [OpenProfil, setOpenProfil] = useState(false);

    const tittleTexteImpactIA =useRef(null);
    useEffect(() => {
      tittleTexteImpactIA.current.scrollIntoView({behavior:'smooth'});
    }, []);

    const textForLoading="Veuillez patienter un petit moment, vos phrases d'accroche sont en préparation. 😊";
    const [isNewQuestion, setIsNewQuestion]=useState(false);
    const [userInputDescription, setUserInputDescription] = useState('');
    const [userInputName, setUserInputName] =useState('');
    const [userInputActivity, setUserInputActivity] =useState('');
    const [userInputPublicTarget, setUserInputPublicTarget] =useState('');
    const [model, setModel] = useState('entreprise');
    const [modelOptions] = useState([
      {display:'Entreprise', value:'entreprise'},
      {display:'CV', value:'cv'}
    ]);

    const handleInputChange = (e) => {
      if (e.target.value.length <= 500) {
        setUserInputDescription(e.target.value);
        }
    }
    
    const handleInputChangeName = (e) => {
        if (e.target.value.length <= 70) {
            setUserInputName(e.target.value);
        }
    }
          
    const handleInputChangePublicTarget = (e) => {
    if (e.target.value.length <= 100) {
        setUserInputPublicTarget(e.target.value);
        }
    }
 

    const handleInputChangeActivity = (e) => {
        if (e.target.value.length <= 100) {
            setUserInputActivity(e.target.value);
        }
    }

    const [gptResponse, setGptResponse] = useState('');
  
    const [isPrompt, setIsPrompt] =useState(false);
    const [isLoading, setIsLoading] =useState(false);
  
   
    const handleClickScroll = () => {
  
    const element = document.getElementById('response-preserve-newline');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleSubmit = async () => {
    try {
      // Suivi de l'événement avec Google Analytics
      ReactGA.event('send_question_texteImpact',{
        category: 'Utilisateur',
        label: 'Service TexteImpact'
    });        
      //Passe isPrompt a false
      setIsLoading(true);
      setIsPrompt(true);
      //Vérification de la présence de chaque donnée
      if(userInputDescription.length<1){
        swal('Veuillez saisir quelques lignes sur vous ou votre entreprise.');
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      if(userInputName.length<1){
        swal('Veuillez saisir votre nom/entreprise, ou même un nom fictif.');
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      if(userInputName.length<1){
        swal('Veuillez saisir votre nom/entreprise, ou même un nom fictif.');
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      if(userInputActivity.length<1){
        swal("Veuillez saisir votre domaine d'activité.");
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      if(userInputPublicTarget.length<1){
        swal('Veuillez saisir le public que vous souhaiter atteindre.');
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      if(model ===null){
        swal("Veuillez saisir si les phrases d'accroche sont destinées à une entreprise ou un cv.");
        setIsLoading(false);
        setIsPrompt(false);
        return;
      }
      const response = await UserService.postObtainCatchPhraseIA({userInputDescription, userInputName, userInputActivity, userInputPublicTarget, model});
    
      if(response.status === 200){
        setGptResponse(response.data.response);
        setIsPrompt(true);
        setIsLoading(false);
        handleClickScroll();
        setIsNewQuestion(true);
      }
    } catch (error) {
      if(error !=="Network Error"){
        if(error.response.status === 403){
            swal("Veuillez ajouter des crédits si vous souhaitez utiliser ce service.")
      }
      else if(error.response.status === 422){
        swal("Le choix du model ne correspond pas.")
      }
      else if(error.response.status){
        swal("Une dépence externe ne répond pas,veuillez rééssayer plus tard.")
      }  
      else{
        swal("Erreur lors de la communication avec le server, veuillez rééssayer plus tard.")
    }
}
      setIsPrompt(false);
      setIsLoading(false);
    }
    
  };
  
  const askQuestion =()=>{
    setGptResponse('');
    setUserInputDescription('');
    setIsPrompt(false);
    setIsNewQuestion(false);
  }
  const handleClose = () => {
    setOpenProfil(false);
  }
  return (
    <div>
        <div id="TexteImpact">
            <h2 ref={tittleTexteImpactIA}>TexteImpactIA</h2>
            <h3> à votre service</h3>
            <br/> 
        {isLoading ?(<div>
                <Loading textProps={textForLoading}/>
                <br/><br/>
              </div>
              ):(
              <div>
              {isPrompt? (<div id='response-preserve-newlines'>{gptResponse}</div>) : ( <div className='bloc-echange-hugoai'>
                <h4>Optimisez votre impact professionnel avec notre service exclusif de génération d'accroches personnalisées. Que vous cherchiez à séduire un client, impressionner un patron ou rehausser votre CV, notre outil crée trois phrases percutantes adaptées à votre projet. Transformez votre communication en un clin d'œil, que ce soit pour une publicité captivante sur votre site web ou une présentation percutante lors de votre recherche d'emploi. Distinguez-vous avec des accroches qui captivent et marquent les esprits, et laissez notre service booster votre visibilité et votre impact professionnel.</h4>
                <h5>Renseigner le questionnaire ci-dessous, et vous recevrez 3 phrases d'accroche pour votre CV ou Entreprise.</h5>
                <br/>               
                <label>Cela concerne votre :</label>
                <select value={model} onChange={e => setModel(e.target.value)}>
                  {modelOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.display}</option>
                  ))}
                </select>
                <br/><br/>
                <label>Votre nom ou celui de votre entreprise :</label>
                <textarea className='textarea70' value={userInputName} placeholder='Nom...' onChange={handleInputChangeName} />
                <span id="charCount" className='text-compte'>Caractères: {userInputName.length}/70</span>

                <br/><br/>
                <label>Quel public voulez-vous cibler?<br/> (exemple_CV : startUp, chef d'entreprise; exemple_entreprise: senior, femme etc...) :</label>
                <textarea className='textarea100' value={userInputPublicTarget} placeholder='Public Cible...' onChange={handleInputChangePublicTarget} />
                <span id="charCount" className='text-compte'>Caractères: {userInputPublicTarget.length}/100</span>

                <br/><br/>
                <label>Domaine d'activité :</label>
                <textarea className='textarea100' value={userInputActivity} placeholder='Domaine...' onChange={handleInputChangeActivity} />
                <span id="charCount" className='text-compte'>Caractères: {userInputActivity.length}/100</span>

                <br/><br/>
                <label>Décrivez en quelques lignes ce que propose votre entreprise, ou vos compétences et ce que vous souhaitez obtenir si cela concerne un cv :</label>
                <textarea className='textarea' value={userInputDescription} placeholder='Mise en avant de vos compétences ou services...' onChange={handleInputChange} />
                <span id="charCount" className='text-compte'>Caractères: {userInputDescription.length}/500</span>
          
                <div>
                  <button className='buttonFormulaire' onClick={handleSubmit}>Envoyer</button>                 
                </div>
              
                <br/><br/>
                <div></div>
                <br/><br/>
              </div>)}
              
            </div>)}  
            {isNewQuestion?(
                <div>
                  <br/><br/>
                  <button className='buttonFormulaire' onClick={askQuestion}>Nouvelle question</button>                
                </div>
              ):(<div></div>)}
    
    </div>
    {OpenProfil && <ProfilPage onClose={handleClose} initialSelectedSection="CheckoutForm" /> }

    <br/><br/>
    </div>
  )
}

export default ObtainCatchPhrase;