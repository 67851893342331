import React from 'react';
import TextToSpeech from '../components/serviceOnline/TextToSpeech';

function TextToSpeechPage() {
  return (
    <div>
        <TextToSpeech />
    </div>
  )
}

export default TextToSpeechPage;